import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArtistCard from "../../Components/Cards/ArtistCard";
import { ArtistItemModel } from "../../Models";
import "../../Cards.css";
import "../../App.css";
import { GetRelatedArtists } from "../../Services/Algolia/GetRelatedArtists";
import i18n from "../../Translations";

const ViewAllSimilarArtists = () => {
  const { state } = useLocation();
  const { id, profile } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [artists, setArtists] = useState<ArtistItemModel[]>([]);

  const getArtists = useCallback(async (artistId: number) => {
    try {
      const { artists: retrievedArtists } = await GetRelatedArtists(
        artistId,
        profile
      );

      setArtists(retrievedArtists);
    } catch {
      console.log("error getartists");
    }
  }, []);

  useEffect(() => {
    getArtists(id).then(() => setIsLoading(false));
  }, []);

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{i18n.t("titles.similarArtists")}</h1>
      </div>
      <div className="content-container similar-artists">
        {artists.length
          ? artists.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className={`card-container ${artists.length > 0 && "hover"}`}
                >
                  <ArtistCard item={item} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ViewAllSimilarArtists;
