import {
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  ALGOLIA_DESCENDING_SUFFIX,
} from "../../Constants";
import { AlbumModel } from "../../Models";
import { databaseGetMultipleData, hasFreshData } from "../../Services/Database";
import { ContentTypes } from "../../Types";

import { algoliaSearch } from ".";

const sortByDate = (contents: AlbumModel[]) => {
  return contents.sort((a, b) => {
    const releaseDateA = Number(new Date(a.release_date));
    const releaseDateB = Number(new Date(b.release_date));
    return releaseDateA === releaseDateB
      ? b.id - a.id
      : releaseDateB - releaseDateA;
  });
};

export const GetNewReleases = async (
  type: ContentTypes,
  ignoreCache: boolean,
  profile: any
) => {
  if (
    !ignoreCache &&
    (await hasFreshData(ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME, undefined, 15))
  ) {
    return databaseGetMultipleData<AlbumModel[]>(
      ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
      profile
    ).then((albums: any) => {
      const sortedAlbums = sortByDate(albums.slice(0, 15));

      return {
        data: sortedAlbums,
        shouldSave: false,
      };
    });
  }

  return algoliaSearch<AlbumModel>({
    nullCache: ignoreCache,
    selectedIndex: `${ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME}${ALGOLIA_DESCENDING_SUFFIX}`,
    additionalOptions: {
      facetFilters: `content_type:${type}`,
      length: 15,
      offset: 0,
    },
    profile: profile,
  }).then(({ hits }) => {
    return {
      data: hits,
      shouldSave: true,
    };
  });
};
