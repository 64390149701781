import { MoreVerticalIcon } from "./Icons/all";
import "../ContentContainers.css";
import "../Buttons.css";
import { PlayIcon } from "./Icons/fillIcons";

export const SongLine = ({ item, offsetContainer = false }: any) => {
  return (
    <div
      key={item.id}
      className={`${
        offsetContainer && "content-offset"
      } content-line flex grey-hover`}
    >
      <div className="content-details flex">
        <PlayIcon
          height={32}
          width={24}
          color="#FFFFFF"
          style={{
            position: "absolute",
            opacity: "100%",
            left: offsetContainer ? "44px" : "54px",
            zIndex: 10,
          }}
        />
        <img
          alt={item.title}
          id="content-item"
          src={item.photo.small}
          className="content-image card"
        />
        <div className="content-text flex">
          <p className="content-info title">{item.title}</p>
          <p className="content-info grey-text">{item.artist.display_name}</p>
        </div>
      </div>
      <div className="more-icon flex">
        <MoreVerticalIcon height={24} width={24} color="#B5B2B0" />
      </div>
    </div>
  );
};
