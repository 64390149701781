import _ from "lodash";

import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_SUGGESTED_SUFFIX,
} from "../../Constants";
import { ContentItemModel } from "../../Models";
import { algoliaSearch } from "../Algolia";

const DEFAULT_ARTISTS_IDS = [6, 8, 13, 14, 15, 16, 20, 28, 100, 359, 150];

export const GetSuggestedContent = async (
  artistsIds: number[],
  length: number = 15,
  profile: any
) => {
  const options: any = {
    facetFilters: "content_type:music",
    length: length,
    offset: 0,
    optionalFilters: artistsIds.map((id) => `artist.id:${id}`),
    sumOrFiltersScores: true,
  };

  if (_.isEmpty(artistsIds)) {
    options.optionalFilters = DEFAULT_ARTISTS_IDS.map(
      (id) => `artist.id:${id}`
    );
  }

  return algoliaSearch({
    selectedIndex: `${ALGOLIA_CONTENTS_INDEX_NAME}${ALGOLIA_SUGGESTED_SUFFIX}`,
    additionalOptions: options,
    profile,
  }).then(({ hits }) => {
    return hits as unknown as ContentItemModel[];
  });
};

export const GetSuggestedVideoContent = async ({
  artistId,
  collectionId,
  length = 10,
  page = 1,
  exceptContent = [],
}: {
  artistId: number;
  collectionId?: number;
  length?: number;
  page?: number;
  exceptContent?: number[];
}) => {
  const filters = [`artist.id:${artistId}`];
  if (collectionId) {
    filters.push(`collection.id:${collectionId}`);
  }
  exceptContent.forEach((id) => {
    filters.push(`id:-${id}`);
  });

  const options: any = {
    facetFilters: "content_type:video",
    length: length,
    offset: (page - 1) * length,
    optionalFilters: filters,
    sumOrFiltersScores: true,
  };

  return algoliaSearch({
    selectedIndex: `${ALGOLIA_CONTENTS_INDEX_NAME}${ALGOLIA_SUGGESTED_SUFFIX}`,
    additionalOptions: options,
  }).then(({ hits }) => {
    return hits as unknown as ContentItemModel[];
  });
};
