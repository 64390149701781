import axios from "axios";
import handleError from "./utils/handleError";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const interceptor = () => {
  instance.interceptors.request.use((config) => {
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (response.data.status === "error") {
        return handleError({
          message: response?.data?.errors,
          data: response?.data,
          status: 400,
        });
      }
      return response;
    },
    ({ message, response }) => {
      if (response?.status && response?.status === 401) {
        console.log("response", response, message);
      }
      return handleError({
        message: response?.data?.error || message,
        data: response?.data,
        status: response?.status,
      });
    }
  );
};

export { instance, interceptor };
