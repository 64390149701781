import React from "react";
import ContentLoader from "react-content-loader";
import PlaceholderSVG from "../../Assets/Images/placeholderLogo.svg";
import "../../Cards.css";

export interface ISkeletonProps {
  type?: "rectangle" | "square" | "circle" | "text";
  size?: "large" | "small" | number;
  speed?: number;
  style?: any;
  height?: number;
  width?: number;
  showLogo?: boolean;
  logoSize?: number;
}

const ShapeWithLogo = ({ height, width, showLogo, type }: ISkeletonProps) => {
  const displayLogo = type !== "text" ? showLogo : false;

  return (
    <div className={`shimmer-container ${type === "circle" && "round-logo"}`}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        backgroundColor={"#272625"}
        foregroundColor={"#4F4C4A"}
      >
        {type === "circle" ? (
          <circle cx="112" cy="112" r="112" />
        ) : (
          <rect x="0" y="0" rx="16" ry="16" width="224" height="224" />
        )}
      </ContentLoader>
      <img
        alt="24SixPlaceholderLogo"
        id="placeholderLogo"
        src={PlaceholderSVG}
        height={79.37}
        width={68}
        className={`logo placeholder`}
      />
    </div>
  );
};

const AlbumTextPlaceholder = ({ height, width }: ISkeletonProps) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor={"#272625"}
      foregroundColor={"#4F4C4A"}
    >
      <rect x="0" y="0" rx="4" ry="4" width="224" height="22" />
      <rect x="0" y="26" rx="4" ry="4" width="120" height="22" />
    </ContentLoader>
  );
};

const AlbumSkeleton = () => {
  return (
    <div className="album-skeleton">
      <ShapeWithLogo height={224} width={224} />
      <AlbumTextPlaceholder height={60} width={224} />
    </div>
  );
};

const ArtistSkeleton = () => {
  return (
    <div>
      <ShapeWithLogo height={224} width={224} type="circle" />
      <ContentLoader
        speed={2}
        width={224}
        height={22}
        backgroundColor={"#272625"}
        foregroundColor={"#4F4C4A"}
      >
        <rect x="0" y="0" rx="4" ry="4" width="224" height="22" />
      </ContentLoader>
    </div>
  );
};

export { AlbumSkeleton, ArtistSkeleton };
