export enum AlgoliaIndexes {
  ARTISTS = 'artists',
  ARTISTS_DISPLAY_NAME_ASC = 'artists_display_name_asc',
  ARTISTS_DISPLAY_NAME_DESC = 'artists_display_name_desc',
  CONTENTS = 'contents',
  CONTENTS_TITLE_DESC = 'contents_title_desc',
  CONTENTS_TITLE_ASC = 'contents_title_asc',
  CONTENT_COLLECTION = 'content_collections',
  CONTENT_COLLECTION_NAME_DESC = 'content_collections_name_desc',
  CONTENT_COLLECTION_NAME_ASC = 'content_collections_name_asc',
}

export const ALGOLIA_ARTISTS_INDEX_NAME = AlgoliaIndexes.ARTISTS
export const ALGOLIA_CONTENTS_INDEX_NAME = AlgoliaIndexes.CONTENTS
export const ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME =
  AlgoliaIndexes.CONTENT_COLLECTION

export const ALGOLIA_ASCENDING_SUFFIX = '_asc'
export const ALGOLIA_DESCENDING_SUFFIX = '_desc'
export const ALGOLIA_SUGGESTED_SUFFIX = '_suggested'
export const ALGOLIA_UPLOAD_ASCENDING_SUFFIX = '_upload_asc'
export const ALGOLIA_UPLOAD_DESCENDING_SUFFIX = '_upload_desc'

export const ALGOLIA_ARTISTS_OBJECTID_PREPEND_VALUE = 'App\\Artist::'
export const ALGOLIA_CONTENTS_OBJECTID_PREPEND_VALUE = 'App\\Content::'
export const ALGOLIA_CONTENT_COLLECTIONS_OBJECTID_PREPEND_VALUE =
  'App\\ContentCollection::'

export const ALGOLIA_INDICES_VALUES = {
  [ALGOLIA_ARTISTS_INDEX_NAME]: ALGOLIA_ARTISTS_OBJECTID_PREPEND_VALUE,
  [ALGOLIA_CONTENTS_INDEX_NAME]: ALGOLIA_CONTENTS_OBJECTID_PREPEND_VALUE,
  [ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME]:
    ALGOLIA_CONTENT_COLLECTIONS_OBJECTID_PREPEND_VALUE,
}
