import React, { useContext } from "react";
import UserAvatar from "../Components/UserAvatar";
import { Context } from "../Context/Context";
import { ProfileListModel } from "../Models";
import i18n from "../Translations";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginUserService } from "../Services";

const ProfilePage = () => {
  const { profiles, setMainProfileAndToken } = useContext(Context);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleAvatarPress = async (tempProfile: any) => {
    if (tempProfile.pin_required === 1) {
      navigate("/profiles/pin-verification", {
        state: { ...state, profile: tempProfile },
      });
    } else {
      const { profile, token } = await LoginUserService({
        email: state.email || "",
        password: state.password || "",
        profile_id: tempProfile.id,
      });
      setMainProfileAndToken(profile, token);
      navigate("/home");
    }
  };

  return (
    <div className="onboarding-page profiles-page">
      <div className="logo-text-container">
        <img
          alt="24SixLogo"
          id="logo"
          src={require("../Assets/Images/logo-horiz-yellow.png")}
          height={40}
          width={150}
          className={`logo horizontal`}
        />
        <h1>{i18n.t("selectProfile.title")}</h1>
      </div>
      <div className="avatar-container">
        {profiles.map((profile: ProfileListModel) => {
          return (
            <div key={profile.id}>
              <UserAvatar
                image={profile.avatar_url}
                iconSize={224}
                onClick={() => handleAvatarPress(profile)}
                name={profile.name}
                className={"showHover"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfilePage;
