import React, { useEffect, useState } from "react";

export interface UserAvatarProps {
  image: string | undefined;
  iconSize: number;
  className?: string | undefined;
  onClick?: Function;
  name?: string | undefined;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  image,
  iconSize,
  className,
  onClick = () => {},
  name,
}) => {
  const [validImage, setValidImage] = useState(false);

  useEffect(() => {
    const isValid = userHasImageUrl();
    setValidImage(isValid);
  }, [image]);

  const userHasImageUrl = () => {
    return image !== "" && typeof image === "string";
  };

  return (
    <div className="imageContainer" onClick={() => onClick()}>
      <img
        alt=""
        id="target"
        src={validImage ? image : "../Assets/Images/default-user-image.png"}
        height={iconSize + "!important"}
        width={iconSize}
        className={`imageAvatar ${className}`}
      />
      {name && <p className="avatar-name">{name}</p>}
    </div>
  );
};

export default UserAvatar;
