import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";
import "../Cards.css";
import "../App.css";
import "../Buttons.css";
import i18n from "../Translations";

const Arrows = ({ title, viewAllAction, offsetTitle = false }: any) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <h1
      style={{
        marginLeft: offsetTitle ? "12px" : "10px",
      }}
    >
      {title}
    </h1>
    <div className="arrow-container">
      <p className="view-all" onClick={() => viewAllAction()}>
        {i18n.t("actions.viewAll")}
      </p>
      <LeftArrow /> <RightArrow />
    </div>
  </div>
);

const ScrollableList = ({
  children,
  itemClassName,
  title,
  viewAllAction,
  offsetTitle = false,
}: any) => {
  return (
    <ScrollMenu
      scrollContainerClassName="category-container"
      itemClassName={itemClassName}
      Header={
        <Arrows
          title={title}
          viewAllAction={viewAllAction}
          offsetTitle={offsetTitle}
        />
      }
    >
      {children}
    </ScrollMenu>
  );
};

export default ScrollableList;
