import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import i18n from "../../Translations";
import { useNavigate } from "react-router-dom";

const PublicPlaylists: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
}) => {
  const navigate = useNavigate();

  const viewAllAction = () => {
    navigate("/public-playlists", {
      state: {
        content_type: "music",
        title: i18n.t("titles.publicPlaylists"),
        playlists: collection,
      },
    });
  };

  return (
    <div>
      {collection.length ? (
        <ScrollableList
          title={i18n.t("titles.publicPlaylists")}
          itemClassName={`card-container ${collection.length > 0 && "hover"}`}
          viewAllAction={viewAllAction}
        >
          {collection.map((item: any) => {
            return (
              <div key={item.id}>
                <AlbumCard item={item} isPlayList />
              </div>
            );
          })}
        </ScrollableList>
      ) : null}
    </div>
  );
};

export default PublicPlaylists;
