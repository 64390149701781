import React, { useState } from "react";
import { ButtonProps } from "./types";
import "../../Buttons.css";

const Button: React.FC<ButtonProps> = ({
  buttonRef,
  className,
  type,
  disabled,
  label,
  handleSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePress = async () => {
    setIsLoading(true);
    await handleSubmit();
    setIsLoading(false);
  };

  return (
    <button
      ref={buttonRef}
      className={`button ${className}`}
      onClick={handlePress}
      type={type}
      disabled={isLoading || disabled}
      data-cy={`${label}`}
    >
      {label}
    </button>
  );
};

export default Button;
