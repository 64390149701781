import React from "react";
import { MoreVerticalIcon, ShuffleIcon } from "./Icons/all";
import { PlayIcon } from "./Icons/fillIcons";
import "../ContentContainers.css";
import "../Buttons.css";

const ContentHeader = ({ data, totalTimeOrYear }: any) => {
  const contentCount = data.contents_count || data.contents.length - 1;
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "370px",
          position: "absolute",
          top: "0%",
          zIndex: -1,
          background: `linear-gradient(180deg, ${
            data.photo.color || "#131311"
          } 0%, rgba(19, 18, 17, 0) 100%)`,
        }}
      />
      <div>
        <div className="header-container flex">
          <img
            alt={data.name}
            id="header-image"
            src={data.photo.large}
            className="header-image card"
          />
          <div className="flex header-info">
            <h1 className="info-name">{data.name}</h1>
            <div className="flex metadata">
              <p>{data.creator || data.artist.display_name}</p>
              <div className="header-dot" />
              <p>{`${contentCount} song${contentCount > 1 ? "s" : ""}`}</p>
              <div className="header-dot" />
              <p>{totalTimeOrYear}</p>
            </div>
            <div className="flex center button-container">
              <button className="primary button header-button">
                <PlayIcon height={20} color="#131211" />
                Play
              </button>
              <button className="secondary button header-button">
                <ShuffleIcon height={20} color="#FFB84A" />
                Shuffle
              </button>
              <div className="more-header flex">
                <MoreVerticalIcon height={24} width={24} color="#FFB84A" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentHeader;
