import PromiseB from "bluebird";
import { Hit } from "@algolia/client-search";
import { isEmpty, isEqual } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
} from "../Constants";
import { Context } from "../Context/Context";
import {
  AlbumItemModel,
  AlbumModel,
  ArtistModel,
  BannerModel,
  CategoryModel,
  ContentItemModel,
  ListenedSong,
  PlaylistModel,
} from "../Models";
import { GetArtists } from "../Services/Algolia/GetArtists";
import { GetFeaturedContent } from "../Services/Algolia/GetFeaturedContent";
import { GetNewReleases } from "../Services/Algolia/GetNewReleases";
import { GetTrendingContent } from "../Services/Algolia/GetTrendingContent";
import {
  GetBanners as getBannersApi,
  getFilteredBanners,
} from "../Services/Banners";
import {
  fetchCategoriesContent,
  GetCategories,
  GetContentCategories,
} from "../Services/Content/categories";
import { GetPublicPlaylists } from "../Services/Content/playlist";
import { GetRecentlyListenedSongs } from "../Services/Content/songs";
import { databaseCreateMultipleData } from "../Services/Database";
import { AlgoliaModel } from "../Services/Database/types";
import { ContentTypes } from "../Types";
import "../App.css";
import usePrevious from "../Helpers/usePrevious";
import NewReleases from "./Sections/NewReleases";
import BrowseArtists from "./Sections/BrowseArtists";
import TrendingNow from "./Sections/TrendingNow";
import FeaturedSongs from "./Sections/FeaturedSongs";
import GenresList from "./Sections/GenresList";
import Categories from "./Sections/Categories";
import RecentlyListened from "./Sections/RecentlyListened";
import PublicPlaylists from "./Sections/PublicPlaylists";

export type CategoryData = {
  contents: Hit<AlbumItemModel>[];
  title: string;
  id: number;
  content_type: ContentTypes;
};

interface IData {
  component: any;
  key: string;
}

const MusicContainer = () => {
  const { mainProfile, token } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [categoriesData, setCategoriesData] = useState<CategoryData[]>([]);
  const [bannersData, setBannersData] = useState<BannerModel[]>([]);
  const [newReleases, setNewReleases] = useState<AlbumModel[]>([]);
  const [trendingSongs, setTrendingSongs] = useState<ContentItemModel[]>([]);
  const [publicPlaylists, setPublicPlaylists] = useState<PlaylistModel[]>([]);
  const [genres, setGenres] = useState<CategoryModel[]>([]);
  const [artists, setArtists] = useState<ArtistModel[]>([]);
  const [featuredSongs, setFeaturedSongs] = useState<ContentItemModel[]>([]);
  const [recentlyListened, setRecentlyListened] = useState<ListenedSong[]>([]);
  const [data, setData] = useState<any>([]);
  const prevData = usePrevious(mainProfile);

  useEffect(() => {
    if (mainProfile !== prevData) {
      fetchAndLoadContent();
    }
  }, [mainProfile, prevData, token]);

  useEffect(() => {
    const newData = getData();
    setData(newData);
  }, [categoriesData, genres, publicPlaylists]);

  const fetchAndLoadContent = async () => {
    await fetchContent();
  };

  // const getBanners = () => {
  //   return (
  //     getBannersApi("music")
  //       //@ts-ignore
  //       .then(getFilteredBanners([], mainProfile))
  //       .then((newBanners) => {
  //         const oldDataIds = bannersData.map((i: any) => [i.id, i.sort_order]);
  //         const newDataIds = newBanners.map((i) => [i.id, i.sort_order]);

  //         if (isEqual(oldDataIds, newDataIds)) {
  //           setBannersData(newBanners);
  //         }
  //       })
  //       .catch(() => {})
  //   );
  // };

  const getCategories = async () => {
    return GetCategories("music", mainProfile).then((newCategories) =>
      fetchCategoriesContent(newCategories, mainProfile)
        .then((newCategoriesData) => {
          if (!isEqual(categoriesData, newCategoriesData)) {
            setCategoriesData(newCategoriesData);
          }
        })
        .catch(() => {})
    );
  };

  const getNewReleases = async (ignoreCache: boolean = true) => {
    return GetNewReleases("music", ignoreCache, mainProfile)
      .then(({ data, shouldSave }) => {
        const oldDataIds = newReleases.map((i) => i.id);
        const newDataIds = data.map((i) => i.id);

        if (!isEqual(oldDataIds, newDataIds)) {
          setNewReleases(data);
          if (shouldSave) {
            databaseCreateMultipleData(
              ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
              data
            );
          }
        }
      })
      .catch(() => {});
  };

  const getTrending = async (ignoreCache: boolean = true) => {
    return GetTrendingContent("music", ignoreCache, mainProfile)
      .then(({ data, shouldSave }) => {
        const oldDataIds = trendingSongs.map((i) => i.id);
        //@ts-ignore
        const newDataIds = data.map((i) => i.id);

        if (!isEqual(oldDataIds, newDataIds)) {
          //@ts-ignore
          setTrendingSongs(data);
          if (shouldSave) {
            databaseCreateMultipleData(
              ALGOLIA_CONTENTS_INDEX_NAME,
              data as never as AlgoliaModel[]
            );
          }
        }
      })
      .catch(() => {});
  };

  const getPlaylists = async () => {
    return GetPublicPlaylists("music", 20)
      .then((playlists) => {
        if (!isEqual(publicPlaylists, playlists)) {
          setPublicPlaylists(playlists ?? []);
        }
      })
      .catch(() => {});
  };

  const getRecentlyListened = async () => {
    return GetRecentlyListenedSongs(mainProfile, "music", 15).then((data) => {
      if (data.length && !isEqual(recentlyListened, data)) {
        setRecentlyListened(data as any);
      }
    });
  };

  const getGenres = async () => {
    return GetContentCategories("music", mainProfile).then((res) => {
      if (!isEqual(genres, res)) {
        setGenres(res);
      }
    });
  };

  const getArtists = async (ignoreCache: boolean = true) => {
    return GetArtists("music", ignoreCache, mainProfile).then((res) => {
      if (!isEqual(artists, res)) {
        setArtists(res);
      }
    });
  };

  const getFeatured = async (ignoreCache: boolean = true) => {
    const followIds = mainProfile?.follows?.map((f) => f.artist_id) || [];

    return GetFeaturedContent(followIds || [], ignoreCache, mainProfile).then(
      (res) => {
        if (!isEqual(featuredSongs, res)) {
          //@ts-ignore
          setFeaturedSongs(res);
        }
      }
    );
  };

  const fetchContent = async () => {
    const promises = [
      //fetch library data before render for avoid components re-renders
      () => getNewReleases(),
      () => getTrending(),
      () => getPlaylists(),
      // () => getRecentlyListened(),
      () => getGenres(),
      () => getArtists(),
      () => getFeatured(),
      () => getCategories(),
    ];

    PromiseB.map(promises, (fetchContent) => fetchContent(), {
      concurrency: 3,
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getData = () => {
    const dataRaw: IData[] = [];

    // if (!_.isEmpty(bannersData)) {
    //   dataRaw.push({
    //     component: Banners,
    //     props: { type: 'music', banners: bannersData },
    //     key: 'banner',
    //   })
    // }

    if (!isEmpty(newReleases)) {
      dataRaw.push({
        component: <NewReleases collection={newReleases} />,
        key: "nr",
      });
    }

    if (!isEmpty(trendingSongs)) {
      dataRaw.push({
        component: <TrendingNow collection={trendingSongs} />,
        key: "tn",
      });
    }

    if (!isEmpty(publicPlaylists)) {
      dataRaw.push({
        component: <PublicPlaylists collection={publicPlaylists} />,
        key: "pp",
      });
    }

    if (!isEmpty(recentlyListened)) {
      dataRaw.push({
        component: <RecentlyListened collection={recentlyListened} />,
        key: "rl",
      });
    }

    if (!isEmpty(genres)) {
      dataRaw.push({
        component: <GenresList collection={genres} />,
        key: "cl",
      });
    }

    if (!isEmpty(artists)) {
      dataRaw.push({
        component: <BrowseArtists collection={artists} />,
        key: "ba",
      });
    }

    if (!isEmpty(featuredSongs)) {
      const followedIds = mainProfile?.follows?.map((f) => f.artist_id) || [];
      dataRaw.push({
        component: (
          <FeaturedSongs collection={featuredSongs} followedIds={followedIds} />
        ),
        key: "fs",
      });
    }

    categoriesData.forEach((c) => {
      if (c.contents.length > 0) {
        dataRaw.push({
          component: <Categories collection={c} />,
          key: `cs-${c.id}`,
        });
      }
    });

    return dataRaw;
  };

  return (
    <div className="music-container">
      {data.map((item: IData) => {
        return <div key={item.key}>{item.component}</div>;
      })}
    </div>
  );
};

export default MusicContainer;
