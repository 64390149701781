import { ContentTypes } from "../../Types";

import { api } from "../index";

export const GetPublicPlaylists = (
  type: ContentTypes | "all" = "music",
  take = 15,
  skip = 0
) => {
  let url = `/public/playlists/${type}`;

  take && (url = url.concat(`/${take}`));
  skip && (url = url.concat(`/${skip}`));

  return api.get(url).then((response) => response.data);
};

export const GetPlaylist = (id: number) =>
  api.get(`/playlist/${id}`).then((response) => response.data);
