import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./Context/MapContext";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import ErrorPage from "./Components/ErrorPage";
import ProfilePage from "./Containers/ProfilePage";
import PinVerificationPage from "./Containers/PinVerificationPage";
import Appheader from "./Components/Appheader";
import MusicContainer from "./Containers/MusicContainer";
import ContentPage from "./Containers/Sections/ContentPage";
import CategoriesPage from "./Containers/Sections/CategoriesPage";
import PlayListsPage from "./Containers/Sections/PlaylistsPage";
import Playlist from "./Containers/Sections/Playlist";
import AlbumDetails from "./Containers/Sections/AlbumDetails";
import ViewAllArtistAlbums from "./Containers/Sections/ViewAllArtistAlbums";
import ArtistDetails from "./Containers/Sections/ArtistDetails";
import ViewAllSimilarArtists from "./Containers/Sections/ViewAllSimilarArtists";
import ViewAllTopSongs from "./Containers/Sections/ViewAllTopSongs";

const router = createBrowserRouter([
  {
    element: (
      <>
        <Appheader />
        <ScrollRestoration />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/home",
        element: <MusicContainer />,
      },
      {
        path: "/profiles",
        element: <ProfilePage />,
      },
      {
        path: "/profiles/pin-verification",
        element: <PinVerificationPage />,
      },
      {
        path: "/content/:contentType",
        element: <ContentPage />,
      },
      {
        path: "/categories",
        element: <CategoriesPage />,
      },
      {
        path: "/public-playlists",
        element: <PlayListsPage />,
      },
      {
        path: "/playlist/:title",
        element: <Playlist />,
      },
      {
        path: "/album/:title",
        element: <AlbumDetails />,
      },
      {
        path: "/artist/:artistname",
        element: <ArtistDetails />,
      },
      {
        path: "/view-all/artists/:artistname",
        element: <ViewAllArtistAlbums />,
      },
      {
        path: "/view-related/artists",
        element: <ViewAllSimilarArtists />,
      },
      {
        path: "/:artist/top-songs",
        element: <ViewAllTopSongs />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
