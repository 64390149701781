// @ts-nocheck
import { Model } from "@nozbe/watermelondb";
import { date, field, json, text } from "@nozbe/watermelondb/decorators";

export default class Artist extends Model {
  static table = "artists";

  @field("algolia_id") algoliaId;
  @text("display_name") display_name;
  @text("bio") bio;
  @json("photo", (val) => val) photo;
  @field("popularity_score") popularity_score;
  @json("collections", (val) => val) collections;
  @json("tags", (val) => val) tags;
  @json("flags", (val) => val) flags;
  @json("categories", (val) => val) categories;
  @date("created_at") createdAt;
  @date("updated_at") updatedAt;
}
