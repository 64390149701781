import { Q } from "@nozbe/watermelondb";

import database from "../../Database";
import { AlgoliaIndices } from "../../Types";

import { AlgoliaModel, DatabaseModels } from "./types";
import {
  filterByBlockedContent,
  filterDataByFlags,
  sanitizeData,
  serializeDataAfterRetrieving,
  serializeDataBeforeStoring,
} from "./util";

/** Retrieve multiple data from database. */
export async function databaseGetMultipleData<T>(
  indexName: AlgoliaIndices,
  profile?: any,
  length?: number,
  query?: Q.Clause
) {
  const queries = [];

  !!query && queries.push(query);
  !!length && queries.push(Q.take(length));

  const data = await database
    .get<DatabaseModels[typeof indexName]>(indexName)
    .query(...queries)
    .fetch();

  const serializedData = data.map((item) =>
    serializeDataAfterRetrieving(indexName, item)
  );

  sanitizeData(indexName);

  const filteredData = filterByBlockedContent(
    filterDataByFlags(serializedData, profile),
    profile
  );

  return filteredData as unknown as T;
}

/** Create multiple data in database from Algolia objects. */
export async function databaseCreateMultipleData(
  indexName: AlgoliaIndices,
  items: AlgoliaModel[]
) {
  await database.write(async () => {
    const collection = database.get(indexName);

    const modelsToBeCreated = items.map((item) => {
      const serializedItem = serializeDataBeforeStoring(indexName, item);

      return collection.prepareCreate((model) => {
        for (let key in serializedItem) {
          //@ts-ignore
          model[key] = serializedItem[key];
        }
      });
    });

    await database.batch(...modelsToBeCreated);
  });

  sanitizeData(indexName);
}

/** Verify whether there is fresh data in a collection.   */
export async function hasFreshData(
  indexName: AlgoliaIndices,
  query?: Q.Clause,
  length: number = 0
) {
  const collection = database.get(indexName);

  const queries = [];

  !!query && queries.push(query);

  const dataAmount = await collection.query(...queries).fetchCount();

  // expired data are always removed. If this is greater than 0,
  // it means that there is fresh data in the current collection.
  const isThereFreshData = dataAmount === 0 ? false : dataAmount >= length;

  return isThereFreshData;
}
