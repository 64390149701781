// @ts-nocheck
import { Model } from "@nozbe/watermelondb";
import { date, field, json, text } from "@nozbe/watermelondb/decorators";

export default class ContentCollection extends Model {
  static table = "content_collections";

  @field("algolia_id") algoliaId;
  @text("name") name;
  @text("content_type") content_type;
  @field("popularity_score") popularity_score;
  @json("photo", (val) => val) photo;
  @json("artist", (val) => val) artist;
  @json("contents", (val) => val) contents;
  @json("other_artists", (val) => val) other_artists;
  @json("tags", (val) => val) tags;
  @json("flags", (val) => val) flags;
  @date("release_date") release_date;
  @date("created_at") createdAt;
  @date("updated_at") updatedAt;
}
