import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../Header.css";
import { Context } from "../Context/Context";
import UserAvatar from "./UserAvatar";
import "../Icons.css";
import {
  ArrowDownIcon,
  FolderIcon,
  MicroIcon,
  MusicTwoIcon,
  SearchIcon,
  VideoIcon,
} from "./Icons/headerIcons";
import { arrContainsAtLeastOneIdenticalValue } from "../Helpers/arrContainsIdenticalValues";

const HeaderCategory = ({ path, title, icon, activePath }: any) => {
  return (
    <div className="header-icon-container">
      <div className="header category-icon">
        {icon}
        <p
          className="header-text"
          style={{
            color: `${path.includes(activePath) ? "#ffb84a" : "#B5B2B0"}`,
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

const Appheader = () => {
  const { mainProfile } = useContext(Context);
  const location = useLocation();
  const path = location.pathname.split("/").join(" ").trimStart();
  const fullHeaderPaths = [
    "home",
    "content",
    "categories",
    "public-playlists",
    "playlist",
    "album",
    "artist",
    "view-all",
    "view-related",
    "top-songs",
  ];
  const showExtraOptions = arrContainsAtLeastOneIdenticalValue(
    fullHeaderPaths,
    location.pathname.split("/")
  );

  const HeaderCategoryData = [
    {
      title: "Music",
      activePath: path === "home" ? "home" : showExtraOptions ? path : null,
      icon: (
        <MusicTwoIcon
          height={32}
          width={32}
          color={showExtraOptions ? "#ffb84a" : "#B5B2B0"}
        />
      ),
    },
    {
      title: "Video",
      activePath: "video",
      icon: (
        <VideoIcon
          height={32}
          width={32}
          color={path === "video" ? "#ffb84a" : "#B5B2B0"}
        />
      ),
    },
    {
      title: "Podcasts",
      activePath: "podcasts",
      icon: (
        <MicroIcon
          height={32}
          width={32}
          color={path === "podcasts" ? "#ffb84a" : "#B5B2B0"}
        />
      ),
    },
    {
      title: "Library",
      activePath: "library",

      icon: (
        <FolderIcon
          height={32}
          width={32}
          color={path === "library" ? "#ffb84a" : "#B5B2B0"}
        />
      ),
    },
    {
      title: "Search",
      activePath: "search",

      icon: (
        <SearchIcon
          height={32}
          width={32}
          color={path === "search" ? "#ffb84a" : "#B5B2B0"}
        />
      ),
    },
  ];

  return (
    <div
      className={`login-header ${path} ${showExtraOptions && "full-header"}`}
    >
      <img
        alt="24SixLogo"
        id="logo"
        src={require("../Assets/Images/logo-horiz-yellow.png")}
        height={25}
        width={100}
        className={`logo horizontal`}
      />
      {showExtraOptions && (
        <>
          <div>
            <div style={{ display: "flex", marginLeft: "40px" }}>
              {HeaderCategoryData.map((item) => {
                return (
                  <HeaderCategory
                    path={path}
                    title={item.title}
                    icon={item.icon}
                    activePath={item.activePath}
                  />
                );
              })}
            </div>
          </div>
          <div className="header user-container">
            <UserAvatar image={mainProfile?.avatar_url} iconSize={48} />
            <p className="avatar-name">
              {Object.keys(mainProfile).length > 0
                ? mainProfile.name.split(" ")[0]
                : ""}
            </p>
            <ArrowDownIcon height={18} width={21} color={"#B5B2B0"} />
          </div>
        </>
      )}
    </div>
  );
};

export default Appheader;
