import { Q } from "@nozbe/watermelondb";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ArtistHeader from "../../Components/ArtistHeader";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import {
  ALGOLIA_ARTISTS_INDEX_NAME,
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
} from "../../Constants";
import { Context } from "../../Context/Context";
import {
  AlbumModel,
  ArtistModel,
  ContentItemModel,
  SongModel,
} from "../../Models";
import { algoliaGetSingleObject, algoliaSearch } from "../../Services";
import { GetAlbumData } from "../../Services/Algolia/GetAlbumData";
import { GetArtistSongs } from "../../Services/Algolia/GetArtistSongs";
import {
  databaseCreateMultipleData,
  databaseGetMultipleData,
  hasFreshData,
} from "../../Services/Database";
import i18n from "../../Translations";
import "../../ContentContainers.css";
import "../../Buttons.css";
import "../../Cards.css";
import "../../App.css";
import { SimilarArtistsList } from "../../Components/SimilarArtistsList";
import { LatestRelease } from "../../Components/LatestRelease";
import { SongLine } from "../../Components/Songline";
import ArtistBio from "../../Components/ArtistBio";

const ArtistDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { mainProfile } = useContext(Context);
  const { state } = useLocation();
  const { id } = state;
  const [artist, setArtist] = useState<ArtistModel | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [topSongs, setTopSongs] = useState<Array<ContentItemModel | SongModel>>(
    []
  );
  const [albums, setAlbums] = useState<AlbumModel[]>([]);
  const [featuredOn, setFeaturedOn] = useState([]);

  const getAlbumData = useCallback(async (artistId: number) => {
    const newAlbums = await databaseGetMultipleData<AlbumModel[]>(
      ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
      mainProfile
    );

    const filteredAlbums = newAlbums.filter(
      (album) => album.artist.id === artistId
    );

    if (filteredAlbums.length >= 12) {
      setAlbums(filteredAlbums.slice(0, 12));
      return Promise.resolve();
    }

    return GetAlbumData(
      artistId,
      "music",
      { length: 15, offset: 0 },
      mainProfile
    )
      .then((res) => {
        setAlbums(res.albums);
        databaseCreateMultipleData(
          ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
          res.albums
        );
      })
      .catch(() => {
        setAlbums(filteredAlbums);
      });
  }, []);

  const getTopSongs = useCallback(async (artistId: number) => {
    const newSongs = await databaseGetMultipleData<ContentItemModel[]>(
      ALGOLIA_CONTENTS_INDEX_NAME,
      mainProfile
    );

    const filteredSongs = newSongs.filter(
      (song) => song.artist?.id === artistId
    );

    if (filteredSongs.length >= 5) {
      setTopSongs(filteredSongs.slice(0, 5));
      return Promise.resolve();
    }

    if (filteredSongs.length > 3) {
      setTopSongs(filteredSongs);
      return Promise.resolve();
    }

    return GetArtistSongs(artistId, { length: 5, offset: 0 })
      .then(({ hits }) => {
        setTopSongs(hits ?? []);
        databaseCreateMultipleData(ALGOLIA_CONTENTS_INDEX_NAME, hits);
      })
      .catch(() => {
        setTopSongs(filteredSongs);
      });
  }, []);

  const getFeaturedOn = useCallback((artistId: number) => {
    return algoliaSearch({
      selectedIndex: ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
      additionalOptions: {
        facetFilters: [`other_artists.id:${artistId}`, "content_type:music"],
      },
      profile: mainProfile,
    }).then(({ hits }) => {
      const data = hits ?? [];
      setFeaturedOn(data as any);
    });
  }, []);

  const getContentsData = (uid: number) => {
    return Promise.all([
      getAlbumData(uid),
      getTopSongs(uid),
      getFeaturedOn(uid),
    ]);
  };

  const getArtistData = useCallback(() => {
    return algoliaGetSingleObject<ArtistModel>({
      id,
      selectedIndex: ALGOLIA_ARTISTS_INDEX_NAME,
      attributes: ["name", "photo", "bio", "id"],
      profile: mainProfile,
    })
      .then((data) => {
        setArtist(data);
        return getContentsData(id);
      })
      .catch(() => {
        console.log("error", "getArtistData");
      });
  }, [id]);

  const navigateViewAllAlbums = useCallback(() => {
    navigate(`/view-all/artists/${params.artistname}`, {
      state: { id },
    });
  }, [id]);

  const navigateViewAllFeatured = () => {
    navigate(`/content/featured-on`, {
      state: {
        index: ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
        content_type: "music",
        title: i18n.t("artist.main.featured"),
        facetFilters: ["content_type:music", `other_artists.id:${id}`],
        contentDisplay: "album",
        attributesToRetrieve: [
          "id",
          "name",
          "photo",
          "artist",
          "contents",
          "release_date",
        ],
      },
    });
  };

  const navigateTopSongs = useCallback(() => {
    navigate(`/${params.artistname}/top-songs`, {
      state: { id: id, profile: mainProfile },
    });
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      const query = Q.where("algolia_id", id);
      if (await hasFreshData(ALGOLIA_ARTISTS_INDEX_NAME, query)) {
        const [retrievedArtist] = await databaseGetMultipleData<ArtistModel[]>(
          ALGOLIA_ARTISTS_INDEX_NAME,
          mainProfile,
          5,
          query
        );

        setArtist(retrievedArtist);

        return getContentsData(id);
      }

      return getArtistData();
    }

    fetchData().finally(() => setIsLoading(false));
  }, [id]);

  return (
    <div>
      {artist && <ArtistHeader data={artist} />}
      <div>
        <LatestRelease artistId={id} profile={mainProfile} />
        {topSongs.length > 0 ? (
          <div className="data-container">
            <div className="flex top-songs">
              <h1>{i18n.t("artist.main.topSongs")}</h1>
              <p className="pointer" onClick={navigateTopSongs}>
                {i18n.t("actions.viewAll")}
              </p>
            </div>
            {topSongs.map((item) => {
              return <SongLine item={item} offsetContainer />;
            })}
          </div>
        ) : null}
        {albums?.length > 0 ? (
          <div className="artist-additional">
            <ScrollableList
              itemClassName={`card-container ${albums.length > 0 && "hover"}`}
              title={i18n.t("artist.main.albums")}
              offsetTitle
              viewAllAction={navigateViewAllAlbums}
            >
              {albums.map((item: any) => {
                return (
                  <div key={item.id}>
                    <AlbumCard item={item} offsetText />
                  </div>
                );
              })}
            </ScrollableList>
          </div>
        ) : null}
        {featuredOn?.length > 0 ? (
          <div className="artist-additional">
            <ScrollableList
              itemClassName={`card-container ${
                featuredOn.length > 0 && "hover"
              }`}
              title={i18n.t("artist.main.featured")}
              offsetTitle
              viewAllAction={navigateViewAllFeatured}
            >
              {featuredOn.map((item: any) => {
                return (
                  <div key={item.id}>
                    <AlbumCard item={item} offsetText />
                  </div>
                );
              })}
            </ScrollableList>
          </div>
        ) : null}
        {artist && !!artist.bio && (
          <ArtistBio
            photo={artist?.photo.large}
            bio={artist?.bio}
            displayName={artist?.display_name}
          />
        )}
        <SimilarArtistsList artistId={id} profile={mainProfile} />
      </div>
    </div>
  );
};

export default ArtistDetails;
