import { intervalToDuration, parse } from 'date-fns'

export default function (date?: string) {
  if (typeof date !== 'string') return 0
  const dateReg = /^\d{4}[./-]\d{2}[./-]\d{2}$/
  if (!date.match(dateReg)) return 0

  const [y, m, d] = date.split('-')
  const birthDate = parse(`${d}/${m}/${y}`, 'dd/MM/yyyy', new Date())
  const { years } = intervalToDuration({ start: birthDate, end: new Date() })
  return years
}
