import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArtistItemModel } from "../Models";
import { GetRelatedArtists } from "../Services/Algolia/GetRelatedArtists";
import i18n from "../Translations";
import ArtistCard from "./Cards/ArtistCard";
import ScrollableList from "./ScrollableList";
import "../ContentContainers.css";

interface Props {
  artistId: number;
  profile: any;
}

const MAX_ARTISTS_RECOMMENDATIONS = 12;

export const SimilarArtistsList = React.memo(({ artistId, profile }: Props) => {
  const navigate = useNavigate();
  const [artists, setArtists] = useState<ArtistItemModel[]>([]);

  useEffect(() => {
    GetRelatedArtists(artistId, profile, MAX_ARTISTS_RECOMMENDATIONS)
      .then((response) => setArtists(response.artists))
      .catch(() => {});
  }, [artistId]);

  if (artists.length === 0) {
    return null;
  }

  return (
    <div className="artist-additional">
      {artists?.length ? (
        <div>
          <ScrollableList
            itemClassName={`card-container ${artists.length > 0 && "hover"}`}
            title={i18n.t("artist.main.similarArtists")}
            offsetTitle
            viewAllAction={() =>
              navigate(`/view-related/artists/`, {
                state: { id: artistId, profile: profile },
              })
            }
          >
            {artists.map((item: any) => {
              return (
                <div key={item.id} className="similar-artist">
                  <ArtistCard item={item} />
                </div>
              );
            })}
          </ScrollableList>
        </div>
      ) : null}
    </div>
  );
});
