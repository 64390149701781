import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AlbumModel } from "../../Models";
import { GetAlbumData } from "../../Services/Algolia/GetAlbumData";
import InfiniteScroll from "react-infinite-scroll-component";
import AlbumCard from "../../Components/Cards/AlbumCard";
import "../../Cards.css";
import "../../App.css";
import { Context } from "../../Context/Context";
import i18n from "../../Translations";

const ViewAllArtistAlbums = () => {
  const { mainProfile } = useContext(Context);
  const { state } = useLocation();
  const id = state.id;

  const [isLoading, setIsLoading] = useState(true);
  const [albums, setAlbums] = useState<AlbumModel[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const isThereMoreContent = !!(currentPage < numberOfPages - 1);

  const getAlbums = useCallback(async (artistId: number, page = 0) => {
    try {
      const { albums: retrievedAlbums, nbPages } = await GetAlbumData(
        artistId,
        "music",
        {
          hitsPerPage: 20,
          page,
        },
        mainProfile
      );

      setAlbums((prevAlbums: any) => {
        if (page > 0) {
          return [...prevAlbums, ...retrievedAlbums];
        }

        return retrievedAlbums;
      });

      setNumberOfPages(nbPages);
    } catch {
      console.log("error getalbums");
    }
  }, []);

  const getMoreAlbums = useCallback(() => {
    if (isLoading) {
      return;
    }

    const newCurrentPage = currentPage + 1;
    if (newCurrentPage >= numberOfPages) {
      // this means we have already fetched all the available pages
      return;
    }

    getAlbums(id, newCurrentPage);
    setCurrentPage(newCurrentPage);
  }, [currentPage, numberOfPages, isLoading]);

  useEffect(() => {
    getAlbums(id).then(() => setIsLoading(false));
  }, []);

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{i18n.t("titles.albums")}</h1>
      </div>
      <InfiniteScroll
        className="content-container"
        dataLength={albums.length} //This is important field to render the next data
        next={getMoreAlbums}
        loader={<div></div>}
        hasMore={isThereMoreContent}
      >
        {albums.length
          ? albums.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className={`card-container ${albums.length > 0 && "hover"}`}
                >
                  <AlbumCard item={item} offsetText />;
                </div>
              );
            })
          : null}
      </InfiniteScroll>
    </div>
  );
};

export default ViewAllArtistAlbums;
