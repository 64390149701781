import { compact, sortBy } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentHeader from "../../Components/ContentHeader";
import { MoreVerticalIcon } from "../../Components/Icons/all";
import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
} from "../../Constants";
import "../../ContentContainers.css";
import "../../Buttons.css";
import "../../Cards.css";
import { Context } from "../../Context/Context";
import { AlbumItemModel, AlbumModel, ArtistItemModel } from "../../Models";
import { algoliaGetMultipleById, algoliaGetSingleObject } from "../../Services";
import { GetMoreAlbumContent } from "../../Services/Algolia/GetMoreAlbumContent";
import { PlayIcon } from "../../Components/Icons/fillIcons";
import ScrollableList from "../../Components/ScrollableList";
import AlbumCard from "../../Components/Cards/AlbumCard";
import i18n from "../../Translations";

const AlbumDetails: React.FC<any> = () => {
  const { mainProfile } = useContext(Context);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [album, setAlbum] = useState<AlbumModel | null>(null);
  const [description, setDescription] = useState<string>("");
  const [albumContents, setAlbumContents] = useState<any>([]);
  const [moreContentFromArtist, setMoreContentFromArtist] =
    useState<Array<AlbumItemModel> | null>(null);
  const [isAlbumContentsLoaded, setIsAlbumContentsLoaded] =
    useState<boolean>(false);
  const [albumYear, setAlbumYear] = useState<number | null>(null);
  const [isFullAlbum, setIsFullAlbum] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hoverIcon, setHoverIcon] = useState<number>(-1);

  useEffect(() => {
    if (!id && id !== 0) {
      console.log("error id");
      return;
    }
    getAlbumData(id);
  }, [id]);

  const getAlbumContents = useCallback(async (ids: Array<string>) => {
    try {
      const { results } = await algoliaGetMultipleById({
        ids,
        selectedIndex: ALGOLIA_CONTENTS_INDEX_NAME,
        profile: mainProfile,
      });

      const sorted = sortBy(compact(results), "collection_number");

      setAlbumContents(sorted);
    } catch {
      console.log("error getalbumcontents");
    } finally {
      setIsAlbumContentsLoaded(true);
    }
  }, []);

  const getArtistsAsText = (
    artist: Partial<ArtistItemModel>,
    other_artists: Array<ArtistItemModel> = []
  ) => {
    const artistArray = [artist, ...other_artists];
    return artistArray.map((a) => a.display_name ?? "").join(", ");
  };

  const getMoreAlbumContent = async (al: AlbumModel) => {
    try {
      const data = await GetMoreAlbumContent(id, al.artist.id, mainProfile);
      setMoreContentFromArtist(data.hits);
    } catch {
      console.log("error getmorealbumcontent");
    }
  };

  const getAlbumActionSheetDescription = (al: AlbumModel) =>
    getArtistsAsText(al.artist, al.other_artists ?? []);

  const setData = (albumData: AlbumModel) => {
    setAlbum(albumData);
    getAlbumContents(albumData.contents.map((c) => c.id));
    setAlbumYear(
      albumData.release_date
        ? new Date(albumData.release_date).getFullYear()
        : null
    );
    setDescription(getAlbumActionSheetDescription(albumData));
    setIsFullAlbum(albumData.contents.length === albumData.contents_count);

    getMoreAlbumContent(albumData);
  };

  const getAlbumData = useCallback(
    async (albumId: number) => {
      try {
        const response = await algoliaGetSingleObject<AlbumModel>({
          id: albumId,
          selectedIndex: ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
          attributes: [""],
          profile: mainProfile,
        });

        setData(response);
      } catch (err) {
        console.log("error getalbumdata");
      } finally {
        setIsLoading(false);
      }
    },
    [mainProfile]
  );

  const handleViewAll = useCallback(() => {
    navigate(`/view-all/artists/${album?.artist.display_name}`, {
      state: {
        id: album?.artist.id,
      },
    });
  }, [album]);

  const toggleHover = (index: number) => {
    setHoverIcon(index);
  };

  return (
    <div>
      {album && <ContentHeader data={album} totalTimeOrYear={albumYear} />}
      <div className="album-content">
        {albumContents.map((item: any, idx: any) => {
          return (
            <div
              key={item.id}
              className="content-line flex grey-hover "
              onMouseEnter={() => toggleHover(idx)}
              onMouseLeave={() => toggleHover(-1)}
            >
              <div className="flex">
                <div className="playicon-container flex">
                  {idx === hoverIcon ? (
                    <PlayIcon height={22} width={22} color="#B5B2B0" />
                  ) : (
                    <p className="grey-text albumdetails-text">{`${
                      idx + 1
                    }.`}</p>
                  )}
                </div>
                <p className="song-title albumdetails-text white-text">
                  {item.title}
                </p>
              </div>
              <div className="more-icon flex">
                <MoreVerticalIcon height={24} width={24} color="#B5B2B0" />
              </div>
            </div>
          );
        })}
        {moreContentFromArtist?.length ? (
          <div className="more-by-list">
            <ScrollableList
              itemClassName={`card-container ${
                moreContentFromArtist.length > 0 && "hover"
              }`}
              title={i18n.t("album.moreByArtist", {
                artist: album?.artist.display_name,
                interpolation: { escapeValue: false },
              })}
              viewAllAction={handleViewAll}
            >
              {moreContentFromArtist.map((item: any) => {
                return (
                  <div key={item.id}>
                    <AlbumCard item={item} />
                  </div>
                );
              })}
            </ScrollableList>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AlbumDetails;
