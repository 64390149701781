import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../Cards.css";
import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  UNICODE_LTR_ISOLATE,
} from "../../Constants";

interface Props {
  item: any;
  imageClass?: string;
}

const GenreCard: React.FC<Props> = ({ item }: any) => {
  const navigate = useNavigate();

  const handleOnPress = useCallback(() => {
    const { name, id, content_type } = item;
    navigate(`/content/${name}`, {
      state: {
        contentDisplay: content_type === "video" ? "video" : "album",
        facetFilters: [
          `content_type:${content_type}`,
          `categories.category_id:${id}`,
        ],
        index:
          content_type === "video"
            ? ALGOLIA_CONTENTS_INDEX_NAME
            : ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
        content_type: content_type,
        title: name,
      },
    });
  }, [item.id, item.content_type, item.name]);

  return (
    <>
      {Object.keys(item).length > 0 ? (
        <div key={item.id} className="wrap" onClick={() => handleOnPress()}>
          <img
            alt={item.name}
            id="genre-image"
            src={item.background_url}
            className="genre-image"
          />
          <div className="genre-text-container">
            <h1 className="genre-text">
              {UNICODE_LTR_ISOLATE}
              {item.name}
            </h1>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GenreCard;
