import PromiseB from "bluebird";
import _ from "lodash";

import { ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME } from "../../Constants";
import { AlbumItemModel, CategoryModel, ProfileModel } from "../../Models";
import { algoliaSearch } from "../../Services";
import { AlgoliaIndices, ContentTypes } from "../../Types";

import { api } from "../index";

export type CategoryScope = "homepage" | string | undefined;

export const GetContentCategories = async (
  content_type: ContentTypes,
  profile?: any,
  scope?: CategoryScope,
  enableProfileFilters: boolean = true
) => {
  const blockedCategories = _.get(
    profile,
    "permissionFilterSettings.blockedCategories.blockedIds",
    null
  );
  return api
    .get<CategoryModel[]>(
      `public/categories/${content_type}${scope ? `/${scope}` : ""}`
    )
    .then((response) => {
      if (
        blockedCategories &&
        blockedCategories.length > 0 &&
        response?.data.length > 0 &&
        enableProfileFilters
      ) {
        return response.data.filter(
          (c) => blockedCategories.indexOf(c.id) === -1
        );
      }
      return response.data;
    });
};

export const GetCategories = async (
  type = "music",
  profile: any = {},
  scope = "homepage",
  applyPermissionFilter = true
) => {
  const blockedCategories = applyPermissionFilter
    ? _.get(
        profile,
        "permissionFilterSettings.blockedCategories.blockedIds",
        null
      )
    : null;

  return api
    .get<CategoryModel[]>(`/public/categories/${type}/${scope}`)
    .then((response) => {
      if (
        blockedCategories &&
        blockedCategories.length > 0 &&
        response?.data.length > 0
      ) {
        return response.data.filter(
          (c) => blockedCategories.indexOf(c.id) === -1
        );
      }
      return response.data;
    });
};

const fetchCategoryContent = async (
  category: CategoryModel,
  content_type: ContentTypes,
  selectedIndex: AlgoliaIndices = ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  profile: any
) => {
  const { hits } = await algoliaSearch<AlbumItemModel>({
    selectedIndex,
    additionalOptions: {
      facetFilters: [
        `content_type:${content_type}`,
        `categories.category_id:${category.id}`,
      ],
      length: 15,
      offset: 0,
    },
    profile,
  });

  return {
    contents: hits,
    title: category.name,
    id: category.id,
    content_type: category.content_type,
  };
};

export const fetchCategoriesContent = (
  categories: CategoryModel[],
  profile: any,
  content_type: ContentTypes = "music",
  selectedIndex: AlgoliaIndices = ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME
) => {
  const sortedCategories = categories.sort(
    (catA, catB) => catA.sort_order - catB.sort_order
  );

  return PromiseB.map(
    sortedCategories,
    (category) =>
      fetchCategoryContent(category, content_type, selectedIndex, profile),
    { concurrency: 2 }
  );
};
