import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ContentItemModel } from "../../Models";
import { GetArtistSongs } from "../../Services/Algolia/GetArtistSongs";
import InfiniteScroll from "react-infinite-scroll-component";
import AlbumCard from "../../Components/Cards/AlbumCard";
import "../../Cards.css";
import "../../App.css";
import i18n from "../../Translations";

const ViewAllTopSongs = () => {
  const { state } = useLocation();
  const { id, profile } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [topSongs, setTopSongs] = useState<ContentItemModel[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const isThereMoreContent = !!(currentPage < numberOfPages - 1);

  const getTopSongs = useCallback((artistId: number, page = 0) => {
    return GetArtistSongs(
      artistId,
      {
        page,
        hitsPerPage: 20,
      },
      profile
    )
      .then(({ hits, nbPages, page }) => {
        //@ts-ignore
        setTopSongs((prevTopSongs) => {
          if (page > 0) {
            return [...prevTopSongs, ...hits];
          }

          return hits;
        });

        setNumberOfPages(nbPages);
      })
      .catch(() => console.log("error getTopSongs"));
  }, []);

  const getMoreSongs = useCallback(() => {
    const newCurrentPage = currentPage + 1;
    if (newCurrentPage >= numberOfPages) {
      // this means we have already fetched all the available pages
      return;
    }
    getTopSongs(id, newCurrentPage);
    setCurrentPage(newCurrentPage);
  }, [currentPage, numberOfPages]);

  useEffect(() => {
    getTopSongs(id).then(() => setIsLoading(false));
  }, []);

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{i18n.t("artist.main.topSongs")}</h1>
      </div>
      <InfiniteScroll
        className="content-container"
        dataLength={topSongs.length} //This is important field to render the next data
        next={getMoreSongs}
        loader={<div></div>}
        hasMore={isThereMoreContent}
      >
        {topSongs.length
          ? topSongs.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className={`card-container ${topSongs.length > 0 && "hover"}`}
                >
                  <AlbumCard item={item} offsetText />;
                </div>
              );
            })
          : null}
      </InfiniteScroll>
    </div>
  );
};

export default ViewAllTopSongs;
