export const clearedContentTypePermissionState = {
  music: "all",
  video: "all",
  podcast: "all",
};

export const clearedBlockedArtistsPermissionState = {
  algoliaQueries: {
    id: "",
    artistId: "",
    otherArtistsId: "",
    contentCollectionArtistId: "",
  },
  blockedIds: [],
  blockedIdsObjectMap: null,
};

export const clearedBlockedCategoriesPermissionState = {
  algoliaQueries: {
    id: "",
    artistCategoriesCategoryId: "",
    categoriesCategoryId: "",
    contentCollectionCategoriesCategoryId: "",
  },
  blockedIds: [],
  blockedIdsObjectMap: null,
};

export const clearedKolIshaState = {
  algoliaQueries: {
    flagsKolIsha: "",
  },
  isBlocked: false,
};

export const clearedAdultOnlyState = {
  algoliaQueries: {
    flagsAdultOnly: "",
  },
  isBlocked: false,
};

export const clearedPermissionFilterState = {
  ...clearedContentTypePermissionState,
  filterString: "",
  blockedArtists: clearedBlockedArtistsPermissionState,
  blockedCategories: clearedBlockedCategoriesPermissionState,
  blockedKolIsha: clearedKolIshaState,
  adultOnly: clearedAdultOnlyState,
};
