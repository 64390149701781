import React from "react";
import ArrowRight from "../../Assets/Images/arrow-right.svg";
import "../../Icons.css";

interface ArrowProps {
  height: number;
  width: number;
}

const ArrowRightIcon: React.FC<ArrowProps> = ({ height, width }) => {
  return (
    <img
      width={width}
      height={height}
      className="arrow right"
      src={ArrowRight}
      alt="right-arrow"
    />
  );
};

const ArrowLeftIcon: React.FC<ArrowProps> = ({ height, width }) => {
  return (
    <img
      width={width}
      height={height}
      className="arrow left"
      src={ArrowRight}
      alt="left-arrow"
    />
  );
};

export { ArrowRightIcon, ArrowLeftIcon };
