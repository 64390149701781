import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import { useNavigate } from "react-router-dom";
import { ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME } from "../../Constants";

const Categories: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
}) => {
  const navigate = useNavigate();

  const viewAllAction = () => {
    const { content_type, id, title, contents, selectedIndex } = collection;
    navigate(`/content/${title}`, {
      state: {
        contentDisplay: content_type === "video" ? "video" : "album",
        facetFilters: [
          `content_type:${content_type}`,
          `categories.category_id:${id}`,
        ],
        index: selectedIndex || ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
        content_type: content_type,
        title: title,
        initialData: contents,
      },
    });
  };

  return (
    <div className="categories-margin">
      {Object.keys(collection.title).length ? (
        <ScrollableList
          itemClassName={`card-container ${
            Object.keys(collection).length > 0 && "hover"
          }`}
          title={`${collection.title}`}
          viewAllAction={viewAllAction}
        >
          {collection.contents.map((item: any) => {
            return (
              <div key={item.id}>
                <AlbumCard item={item} />
              </div>
            );
          })}
        </ScrollableList>
      ) : null}
    </div>
  );
};

export default Categories;
