import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "../../ContentContainers.css";
import "../../Buttons.css";
import { useLocation, useNavigate } from "react-router-dom";
import { PlaylistModel } from "../../Models";
import { secondsToTime } from "../../Helpers";
import { GetPlaylist } from "../../Services/Content/playlist";
import { algoliaGetMultipleById } from "../../Services";
import { ALGOLIA_CONTENTS_INDEX_NAME } from "../../Constants";
import { Context } from "../../Context/Context";
import _ from "lodash";
import ContentHeader from "../../Components/ContentHeader";
import { SongLine } from "../../Components/Songline";

const Playlist: React.FC<any> = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, playlistData } = state;
  const { mainProfile } = useContext(Context);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isContentsLoaded, setIsContentsLoaded] = useState<boolean>(false);
  const [isAllSongsSaved, setAllSaved] = useState<boolean>(false);

  const [playlist, setPlaylist] = useState<PlaylistModel | null>(
    playlistData || null
  );

  const [playlistContents, setPlaylistContents] = useState<any>([]);

  const calculateTotalTime = useMemo(() => {
    const secondsTotal = playlistContents.reduce(
      (total: any, c: any) => (total += c.length_in_seconds || 0),
      0
    );
    return secondsToTime(secondsTotal, "unit");
  }, [playlistContents]);

  const getPlaylistData = useCallback(async (playlistId: number) => {
    try {
      const response = await GetPlaylist(playlistId);
      setPlaylist(response);
      getContentData(response.contents, response.is_public);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getContentData = useCallback(
    async (content_ids: Array<number | string>, isPublic = false) => {
      try {
        const hits = await algoliaGetMultipleById({
          ids: content_ids,
          selectedIndex: ALGOLIA_CONTENTS_INDEX_NAME,
          disableKolIshaFilter: !isPublic,
          blockArtistById: isPublic,
          profile: mainProfile,
        });
        const data = hits.results || [];
        setPlaylistContents(
          data.map((i) => ({ ...i, _id: _.uniqueId() })) as any
        );
      } catch {
        console.log("err");
      } finally {
        setIsContentsLoaded(true);
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!id && id !== 0) {
      console.log("err id");

      return;
    }

    if (!playlistData) {
      getPlaylistData(id);
    } else {
      getContentData(playlistData.contents, playlistData.is_public);
    }
  }, []);

  return (
    <>
      <ContentHeader data={playlistData} totalTimeOrYear={calculateTotalTime} />
      {playlistContents.map((item: any) => {
        return <SongLine item={item} />;
      })}
    </>
  );
};

export default Playlist;
