import { UNICODE_LTR_ISOLATE } from "../Constants";
import { ArtistItemModel } from "../Models";

export function getArtistNamesAsText(
  artist: ArtistItemModel,
  other_artists: ArtistItemModel[] = []
): string {
  if (!artist) {
    return "";
  }

  const artists = [artist, ...other_artists];
  return (
    UNICODE_LTR_ISOLATE +
    artists
      .filter((a) => a.display_name)
      .map((a) => a.display_name)
      .join(", ") +
    UNICODE_LTR_ISOLATE
  );
}
