import React from "react";
import "./App.css";
import MainContainer from "./MainContainer";
import { NativeBaseProvider } from "native-base";

const App = () => {
  return (
    <NativeBaseProvider>
      <MainContainer />
    </NativeBaseProvider>
  );
};

export default App;
