// @ts-nocheck
import { Model } from "@nozbe/watermelondb";
import { date, field, json, text } from "@nozbe/watermelondb/decorators";

export default class Content extends Model {
  static table = "contents";

  @field("algolia_id") algoliaId;
  @text("title") title;
  @text("content_type") content_type;
  @text("description") description;
  @text("lyrics") description;
  @json("photo", (val) => val) photo;
  @json("other_artists", (val) => val) other_artists;
  @json("artist", (val) => val) artist;
  @json("content_collection", (val) => val) content_collection;
  @json("languages", (val) => val) languages;
  @json("tags", (val) => val) tags;
  @json("flags", (val) => val) flags;
  @json("categories", (val) => val) categories;
  @field("length_in_seconds") length_in_seconds;
  @field("collection_number") collection_number;
  @field("popularity_score") popularity_score;
  @date("upload_date") upload_date;
  @date("created_at") createdAt;
  @date("updated_at") updatedAt;
}
