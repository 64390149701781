import {
  PERMISSION_ARTIST_ENTITY,
  PERMISSION_CATEGORY_ENTITY,
} from "../Constants";
import {
  AdultOnlyPermissionFilter,
  ArtistModel,
  ContentCollectionModel,
  ContentItemModel,
  IPermissionModel,
  KolIshaPermissionFilter,
  PermissionRuleModel,
  ProfileAlgoliaPermissionFilterSettings,
  ProfileModel,
} from "../Models";
import calculateAge from "../Services/utils/calculateAge";
import { ArtistTypes, IndexItemType, TPermissionAvailability } from "../Types";

import { generateHashMapObjectFromArray } from "./hashmap";

export function generateFacetFilterString(ids: number[], facet: string) {
  if (!ids || ids.length === 0 || !facet) return "";
  const res = ids.map((id) => `${facet}:${id}`).join(" AND NOT ");
  return `(NOT ${res})`;
}

export function generateAdultOnlyString(
  profile: ProfileModel,
  defaultAdultOnlySettings: AdultOnlyPermissionFilter
) {
  if (!profile) {
    return defaultAdultOnlySettings;
  }
  const dateOfBirth = calculateAge(profile?.date_of_birth);
  const isAdult = dateOfBirth ? dateOfBirth < 18 : false;
  return {
    ...defaultAdultOnlySettings,
    algoliaQueries: {
      ...defaultAdultOnlySettings.algoliaQueries,
      flagsKolIsha: "flags.adult_only:false",
    },
    isBlocked: isAdult,
  };
}

export function generateBlockedKolIshaString(
  profile: ProfileModel,
  defaultKolIshaSettings: KolIshaPermissionFilter
) {
  if (!profile) {
    return defaultKolIshaSettings;
  }
  return {
    ...defaultKolIshaSettings,
    algoliaQueries: {
      ...defaultKolIshaSettings.algoliaQueries,
      flagsKolIsha: "flags.kol_isha:false",
    },
    isBlocked: profile?.gender === "m",
  };
}

export function generateFilterString(
  artistQueryObj: { [key: string]: string } = {},
  categoryQueryObj: { [key: string]: string } = {},
  ignoreId: boolean = true
) {
  const artistString = Object.keys(artistQueryObj)
    .filter((k) => !!artistQueryObj[k])
    .filter((k) => (ignoreId ? k !== "id" : true))
    .map((k) => artistQueryObj[k])
    .join(" AND ");
  const categoryString = Object.keys(categoryQueryObj)
    .filter((k) => !!categoryQueryObj[k])
    .filter((k) => (ignoreId ? k !== "id" : true))
    .map((k) => categoryQueryObj[k])
    .join(" AND ");
  return `${artistString}${
    artistString && categoryString ? " AND " : ""
  }${categoryString}`;
}

interface PermissionFilters
  extends Omit<IPermissionModel, "music" | "video" | "podcasts"> {}

interface PermissionFiltersObject
  extends Omit<
    ProfileAlgoliaPermissionFilterSettings,
    "music" | "video" | "podcast"
  > {}

type ItemObjectType = ContentItemModel & ContentCollectionModel & ArtistModel;

export function generatePermissionFilterObject(
  profilePermissions: PermissionFilters,
  currentPermissionSettings: PermissionFiltersObject
): PermissionFiltersObject {
  const blockedArtistIds =
    profilePermissions?.rules
      .filter(
        (r: PermissionRuleModel) => r.entity_type === PERMISSION_ARTIST_ENTITY
      )
      .map((r: PermissionRuleModel) => r.entity_id) ?? [];
  const blockedCategoryIds =
    profilePermissions?.rules
      .filter(
        (r: PermissionRuleModel) => r.entity_type === PERMISSION_CATEGORY_ENTITY
      )
      .map((r: PermissionRuleModel) => r.entity_id) ?? [];

  const artistQueries = {
    id: generateFacetFilterString(blockedArtistIds, "id"),
    artistId: generateFacetFilterString(blockedArtistIds, "artist.id"),
    otherArtistsId: generateFacetFilterString(
      blockedArtistIds,
      "other_artists.id"
    ),
    contentCollectionArtistId: generateFacetFilterString(
      blockedArtistIds,
      "collection.artist.id"
    ),
  };

  const categoryQueries = {
    id: generateFacetFilterString(blockedCategoryIds, "id"),
    artistCategoriesCategoryId: generateFacetFilterString(
      blockedCategoryIds,
      "artist.categories.category_id"
    ),
    categoriesCategoryId: generateFacetFilterString(
      blockedCategoryIds,
      "categories.category_id"
    ),
    contentCollectionCategoriesCategoryId: generateFacetFilterString(
      blockedCategoryIds,
      "collection.categories.category_id"
    ),
    otherArtistsCategoriesCategoryId: generateFacetFilterString(
      blockedCategoryIds,
      "other_artists.categories.category_id"
    ),
  };
  //@ts-ignore

  return {
    filterString: generateFilterString(artistQueries, categoryQueries),
    blockedArtists: {
      ...currentPermissionSettings.blockedArtists,
      algoliaQueries: {
        ...currentPermissionSettings.blockedArtists.algoliaQueries,
        ...artistQueries,
      },
      blockedIds: blockedArtistIds,
      blockedIdsObjectMap:
        blockedArtistIds && blockedArtistIds.length
          ? generateHashMapObjectFromArray(blockedArtistIds, true)
          : null,
    },
    blockedCategories: {
      ...currentPermissionSettings.blockedCategories,
      algoliaQueries: {
        ...currentPermissionSettings.blockedCategories.algoliaQueries,
        ...categoryQueries,
      },
      blockedIds: blockedCategoryIds,
      blockedIdsObjectMap:
        blockedCategoryIds && blockedCategoryIds.length
          ? generateHashMapObjectFromArray(blockedCategoryIds, true)
          : null,
    },
    blockedKolIsha: {
      ...currentPermissionSettings.blockedKolIsha,
      algoliaQueries: {
        ...currentPermissionSettings.blockedKolIsha.algoliaQueries,
        flagsKolIsha: "",
      },
      isBlocked: false,
    },
    adultOnly: {
      ...currentPermissionSettings.adultOnly,
      algoliaQueries: {
        ...currentPermissionSettings.adultOnly.algoliaQueries,
        flagsAdultOnly: "",
      },
      isBlocked: false,
    },
  };
}

export const doesItemPassTypeFilters = (
  item: ItemObjectType,
  itemType: IndexItemType,
  {
    music,
    podcast,
    video,
  }: {
    music: TPermissionAvailability;
    podcast: TPermissionAvailability;
    video: TPermissionAvailability;
  },
  checkForSpecificArtistFlag?: ArtistTypes
) => {
  const enableMusic =
    music !== "none" ||
    (music === "none" && video === "none" && podcast === "none");
  const enableVideo = video !== "none";
  const enablePodcast = podcast !== "none";
  let enableCount = 0;
  if (enableMusic) enableCount++;
  if (enableVideo) enableCount++;
  if (enablePodcast) enableCount++;

  if (
    (itemType !== "artist" && item.content_type === "music" && !enableMusic) ||
    (item.content_type === "video" && !enableVideo) ||
    (item.content_type === "podcast" && !enablePodcast)
  ) {
    return false;
  }
  //check artist flags
  if (itemType === "artist" && enableCount < 3) {
    if (checkForSpecificArtistFlag) {
      if (checkForSpecificArtistFlag === "musician")
        return enableMusic && item.flags.musician;
      if (checkForSpecificArtistFlag === "video_uploader")
        return enableMusic && item.flags.video_uploader;
      if (checkForSpecificArtistFlag === "podcaster")
        return enableMusic && item.flags.podcaster;
    }
    if (
      (enableMusic && item.flags.musician) ||
      (enableVideo && item.flags.video_uploader) ||
      (enablePodcast && item.flags.podcaster)
    )
      return true;
  }
  return true;
};

export const doesItemPassKolIshaFilters = (
  //@ts-ignore

  item,
  itemType: IndexItemType,
  blockKolIsha: boolean
) => {
  if (!blockKolIsha) return true;

  const otherArtistsKolIsha = item.other_artists
    ? //@ts-ignore

      item.other_artists.some((artist) => artist.flags.kol_isha === true)
    : false;
  if (
    item.flags.kol_isha === true ||
    (item.artist && item.artist.flags.kol_isha === true) ||
    otherArtistsKolIsha ||
    (item.collection && item.collection.flags.kol_isha === true)
  ) {
    return false;
  }

  return true;
};

export const doesItemPassAdultOnlyFilters = (
  item: ItemObjectType,
  itemType: IndexItemType,
  blockAdultOnly: boolean
) => {
  if (!blockAdultOnly || itemType === "artist") return true;

  const otherArtistsKolIsha = item.other_artists
    ? //@ts-ignore

      item.other_artists.some((artist) => artist.flags.adult_only === true)
    : false;
  if (
    item.flags.adult_only === true ||
    //@ts-ignore

    (item.artist && item.artist.flags.adult_only === true) ||
    otherArtistsKolIsha ||
    //@ts-ignore

    (item.collection && item.collection.flags.adult_only === true)
  ) {
    return false;
  }

  return true;
};

export const doesItemPassBlockedArtistFilter = (
  item: ItemObjectType,
  itemType: IndexItemType,
  blockedArtistObj: object | undefined
) => {
  console.log(blockedArtistObj);
  if (!blockedArtistObj || Object.keys(blockedArtistObj).length === 0)
    return true;
  //@ts-ignore

  if (itemType === "artist" && blockedArtistObj[item.id]) return false;
  //@ts-ignore

  if (itemType !== "artist" && blockedArtistObj[item.artist.id]) return false;
  if (
    item.other_artists &&
    item.other_artists.length > 0 &&
    //@ts-ignore

    item.other_artists.some((artist) => blockedArtistObj[artist.id])
  )
    return false;
  return true;
};

export const doesItemPassBlockedCategoriesFilter = (
  item: ItemObjectType,
  itemType: IndexItemType,
  blockedCategoryIdsObject: object | undefined
) => {
  console.log(blockedCategoryIdsObject);
  if (
    !blockedCategoryIdsObject ||
    Object.keys(blockedCategoryIdsObject).length === 0
  )
    return true;
  if (
    item.categories.length > 0 &&
    item.categories.some(
      //@ts-ignore

      (category) => blockedCategoryIdsObject[category.category_id]
    )
  )
    return false;
  if (
    item.collection &&
    item.collection.categories?.length > 0 &&
    item.collection.categories.some(
      //@ts-ignore

      (category) => blockedCategoryIdsObject[category.category_id]
    )
  )
    return false;
  if (
    item.artist &&
    //@ts-ignore

    item.artist.categories?.length > 0 &&
    //@ts-ignore

    item.artist.categories.some(
      //@ts-ignore

      (category) => blockedCategoryIdsObject[category.category_id]
    )
  )
    return false;
  if (item.other_artists && item.other_artists.length > 0) {
    if (
      item.other_artists.some((artist) =>
        //@ts-ignore

        artist.categories.some(
          //@ts-ignore

          (category) => blockedCategoryIdsObject[category.category_id]
        )
      )
    )
      return false;
  }
  return true;
};

export const doesContentPassFilters = (content: ContentItemModel) => {
  // const { user } = store.getState();
  //come back to this
  const user = null;
  //@ts-ignore

  if (!user || !user.permissionFilterSettings) return true;
  const {
    music,
    podcast,
    video,
    adultOnly,
    blockedArtists,
    blockedCategories,
    blockedKolIsha,
    //@ts-ignore
  } = user.permissionFilterSettings;
  if (
    //@ts-ignore

    !doesItemPassTypeFilters(content, "content", {
      music,
      podcast,
      video,
    })
  )
    return false;
  if (
    !doesItemPassKolIshaFilters(content, "content", blockedKolIsha?.isBlocked)
  )
    return false;
  //@ts-ignore

  if (!doesItemPassAdultOnlyFilters(content, "content", adultOnly?.isBlocked))
    return false;

  //checks artist and other_artists
  const blockedArtistIdsObject = blockedArtists.blockedIdsObjectMap || null;
  if (
    //@ts-ignore

    !doesItemPassBlockedArtistFilter(content, "content", blockedArtistIdsObject)
  )
    return false;

  //checks categories, collection.categories, artist.categories, and other_artists.categories
  const blockedCategoryIdsObject =
    blockedCategories.blockedIdsObjectMap || null;
  if (
    !doesItemPassBlockedCategoriesFilter(
      //@ts-ignore

      content,
      "content",
      blockedCategoryIdsObject
    )
  )
    return false;
  return true;
};

export const doesCollectionPassFilters = (
  collection: ContentCollectionModel
) => {
  // const { user } = store.getState();
  //come back to this
  const user = null;
  //@ts-ignore

  if (!user || !user.permissionFilterSettings) return true;
  const {
    music,
    podcast,
    video,
    adultOnly,
    blockedArtists,
    blockedCategories,
    blockedKolIsha,
    //@ts-ignore
  } = user.permissionFilterSettings;

  if (
    //@ts-ignore

    !doesItemPassTypeFilters(collection, "collection", {
      music,
      podcast,
      video,
    })
  )
    return false;

  if (
    !doesItemPassKolIshaFilters(
      collection,
      "collection",
      blockedKolIsha?.isBlocked
    )
  )
    return false;

  if (
    !doesItemPassAdultOnlyFilters(
      //@ts-ignore

      collection,
      "collection",
      adultOnly?.isBlocked
    )
  )
    return false;

  //checks artist and other_artists
  const blockedArtistIdsObject = blockedArtists.blockedIdsObjectMap || null;
  if (
    !doesItemPassBlockedArtistFilter(
      //@ts-ignore

      collection,
      "collection",
      blockedArtistIdsObject
    )
  )
    return false;

  //checks categories, collection.categories, artist.categories, and other_artists.categories
  const blockedCategoryIdsObject =
    blockedCategories.blockedIdsObjectMap || null;
  if (
    !doesItemPassBlockedCategoriesFilter(
      //@ts-ignore

      collection,
      "collection",
      blockedCategoryIdsObject
    )
  )
    return false;

  return true;
};

export const doesArtistPassFilters = (
  artist: ArtistModel,
  checkForSpecificArtistFlag?: ArtistTypes
) => {
  // const { user } = store.getState();
  //come back to this
  const user = null;
  //@ts-ignore

  if (!user || !user.permissionFilterSettings) return true;
  const {
    blockedArtists,
    blockedCategories,
    blockedKolIsha,
    music,
    video,
    podcast,
    //@ts-ignore
  } = user.permissionFilterSettings;

  if (
    !doesItemPassTypeFilters(
      //@ts-ignore

      artist,
      "artist",
      { music, video, podcast },
      checkForSpecificArtistFlag
    )
  )
    return false;

  if (!doesItemPassKolIshaFilters(artist, "artist", blockedKolIsha?.isBlocked))
    return false;

  const blockedArtistIdsObject = blockedArtists.blockedIdsObjectMap || null;
  if (
    //@ts-ignore

    !doesItemPassBlockedArtistFilter(artist, "artist", blockedArtistIdsObject)
  )
    return false;

  //checks categories, collection.categories, artist.categories, and other_artists.categories
  const blockedCategoryIdsObject =
    blockedCategories.blockedIdsObjectMap || null;
  if (
    !doesItemPassBlockedCategoriesFilter(
      //@ts-ignore

      artist,
      "artist",
      blockedCategoryIdsObject
    )
  )
    return false;

  return true;
};
