interface HeaderIconProps {
  color?: string;
  strokeWidth?: number;
  height: number;
  width: number;
}

export const MusicTwoIcon = ({
  color = "#B5B2B0",
  strokeWidth,
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} color="transparent">
      <path
        d="M9 16H6.25245C6.018 16 5.90078 16 5.80205 16.0098C4.85403 16.1041 4.10411 16.854 4.00982 17.802C4 17.9008 4 18.018 4 18.2525V18.2525C4 18.5322 4 18.6721 4.01244 18.7831C4.13321 19.8608 5.0909 20.646 6.17139 20.553C6.28269 20.5435 6.41986 20.516 6.69419 20.4612L6.99029 20.4019C7.37076 20.3258 7.56099 20.2878 7.7215 20.2257C8.3765 19.9724 8.85063 19.394 8.9706 18.7021C9 18.5325 9 18.3385 9 17.9505V9.27922C9 7.70946 9 6.92459 9.45332 6.37163C9.90663 5.81867 10.6763 5.66475 12.2155 5.35689L14.2155 4.95689C16.4291 4.51417 17.536 4.29281 18.268 4.89292C19 5.49304 19 6.62176 19 8.87922V9M19 14V15.9505C19 16.3385 19 16.5325 18.9706 16.7021C18.8506 17.394 18.3765 17.9724 17.7215 18.2257C17.561 18.2878 17.3708 18.3258 16.9903 18.4019L16.6942 18.4612C16.4199 18.516 16.2827 18.5435 16.1714 18.553C15.0909 18.646 14.1332 17.8608 14.0124 16.7831C14 16.6721 14 16.5322 14 16.2525V16.2525C14 16.018 14 15.9008 14.0098 15.802C14.1041 14.854 14.854 14.1041 15.802 14.0098C15.9008 14 16.018 14 16.2525 14H19ZM19 14V9M19 9L9 11"
        stroke={color}
        fillOpacity={0}
        strokeWidth={strokeWidth || "1.5"}
      />
    </svg>
  );
};

export const VideoIcon = ({
  color = "#B5B2B0",
  strokeWidth,
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8163 15.62C15.8163 17.613 14.4583 19.021 12.2863 19.021H6.06929C3.89929 19.021 2.55029 17.613 2.55029 15.62V8.66C2.55029 6.668 3.89929 5.25 6.08029 5.25H12.2863C14.4583 5.25 15.8163 6.668 15.8163 8.66V15.62Z"
        stroke={color}
        fillOpacity={0}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8164 11.2766L19.9134 7.9226C20.5634 7.3876 21.5504 7.8526 21.5504 8.6996V15.5716C21.5504 16.4196 20.5634 16.8826 19.9134 16.3486L15.8164 12.9946"
        stroke={color}
        fillOpacity={0}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MicroIcon = ({
  color = "#B5B2B0",
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" color="transparent" height={height} width={width}>
      <rect
        x="9"
        y="3"
        width="6"
        height="11"
        rx="3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
        fillOpacity={0}
      />
      <path
        d="M18 11C18 12.5913 17.3679 14.1174 16.2426 15.2426C15.1174 16.3679 13.5913 17 12 17C10.4087 17 8.88258 16.3679 7.75736 15.2426C6.63214 14.1174 6 12.5913 6 11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
      <path
        d="M12 21V19"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
    </svg>
  );
};

export const FolderIcon = ({
  color = "#B5B2B0",
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" color="transparent" height={height} width={width}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9757 14.9567C19.9757 18.13 18.1084 20 14.9396 20H8.04422C4.86733 20 3 18.13 3 14.9567V8.04333C3 4.87 4.16405 3 7.33286 3H9.10319C9.73929 3 10.3383 3.29992 10.7199 3.80952L11.5283 4.88619C11.9108 5.3948 12.5092 5.69445 13.145 5.69571H15.651C18.8279 5.69571 20 7.31476 20 10.5529L19.9757 14.9567Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
      <path
        d="M7 15H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
    </svg>
  );
};

export const SearchIcon = ({
  color = "#B5B2B0",
  strokeWidth,
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" color="transparent" height={height} width={width}>
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
      <path
        d="M20.9999 21.0004L16.6499 16.6504"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
    </svg>
  );
};

export const ArrowDownIcon = ({
  color = "#B5B2B0",
  height,
  width,
}: HeaderIconProps) => {
  return (
    <svg viewBox="0 0 24 24" color="transparent" height={height} width={width}>
      <path
        d="M19 8.5L12 15.5L5 8.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
      />
    </svg>
  );
};
