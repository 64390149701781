import { appSchema } from '@nozbe/watermelondb'

import artist from './artist'
import content from './content'
import contentCollection from './contentCollection'

export default appSchema({
  version: 1, // dev note: increment it whenever schema changes.
  tables: [contentCollection, content, artist],
})
