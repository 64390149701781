import React, { useCallback, useContext, useEffect, useState } from "react";
import "../../Cards.css";
import "../../App.css";
import i18n from "../../Translations";
import { useLocation } from "react-router-dom";
import { Context } from "../../Context/Context";
import GenreCard from "../../Components/Cards/GenreCard";
import { CategoryModel } from "../../Models";
import { GetContentCategories } from "../../Services/Content/categories";

const CategoriesPage: React.FC<any> = () => {
  const { state } = useLocation();
  const { mainProfile } = useContext(Context);

  const content_type = state?.content_type;
  const categories = state?.categories;
  const enableProfileFilters =
    typeof state?.enableProfileFilters !== "undefined"
      ? state?.enableProfileFilters
      : true;
  const blockedCategories =
    //@ts-ignore
    mainProfile.permissionFilterSettings?.blockedCategories?.blockedIds ?? null;

  const filterCategories = useCallback(
    (categories: CategoryModel[] = []) => {
      if (
        categories.length > 0 &&
        blockedCategories &&
        blockedCategories.length > 0
      ) {
        return enableProfileFilters
          ? categories.filter((c) => blockedCategories.indexOf(c.id) === -1)
          : categories;
      }
      return categories;
    },
    [blockedCategories, enableProfileFilters]
  );

  const [isLoading, setIsLoading] = useState(categories ? false : true);
  const [contents, setContents] = useState<CategoryModel[]>(
    filterCategories(categories) || []
  );

  const getContents = useCallback(async () => {
    try {
      const categoriesData = await GetContentCategories(
        content_type,
        undefined
      );

      setContents(filterCategories(categoriesData) || []);
    } catch {
      console.log("error");
    }
  }, []);

  useEffect(() => {
    if (!categories || categories.length > 0) {
      getContents().then(() => setIsLoading(false));
    }
  }, []);

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{i18n.t("titles.genres")}</h1>
      </div>
      <div className="content-container">
        {contents.length
          ? contents.map((item: any) => {
              return (
                <div key={item.id} className={`genre-item`}>
                  <GenreCard item={item} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default CategoriesPage;
