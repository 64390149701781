import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import { useLocation, useNavigate } from "react-router-dom";

const PlayListsPage: React.FC<any> = () => {
  const { state } = useLocation();
  const title = state?.title;
  const playlists = state?.playlists;

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{title}</h1>
      </div>
      <div className="content-container">
        {playlists.length
          ? playlists.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className={`card-container ${
                    playlists.length > 0 && "hover"
                  }`}
                >
                  <AlbumCard item={item} offsetText isPlayList />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PlayListsPage;
