import { ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME } from "../../Constants";
import { AlbumItemModel } from "../../Models";
import { algoliaSearch } from "../Algolia";

export const GetMoreAlbumContent = (
  albumId: number,
  artistId: number,
  profile: any
) => {
  const singleObjectData = {
    selectedIndex: ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
    additionalOptions: {
      facetFilters: [
        `artist.id:${artistId}`,
        "content_type:music",
        `id:-${albumId}`,
      ],
    },
    profile: profile,
  };
  //@ts-ignore
  return algoliaSearch<AlbumItemModel>(singleObjectData);
};
