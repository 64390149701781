import i18n from 'i18next'

import { en } from './resources'

export * from './types'

i18n.init({
  compatibilityJSON: 'v3',
  resources: {
    en: {
      translation: en,
    },
  },
  lng: 'en',
})

export default i18n
