import { api } from "../index";

interface Params {
  password: string;
  email: string;
}

interface Response {
  max_profiles: number;
  profiles: any;
}

export default async (data: Params) => {
  return api.post("profiles-for-login", data).then((response) => {
    const res: Response = response.data;
    return res;
  });
};
