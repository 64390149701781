import React, { useState, useRef } from "react";
import "../App.css";
import { FormControl, Input, Stack } from "native-base";
import { Formik, FormikProps } from "formik";
import { useContext } from "react";
import { Context } from "../Context/Context";
import { FormParams } from "./types";
import { useNavigate } from "react-router-dom";
import i18n from "../Translations";
import { Icon } from "../Components/Icons";
import { signInValidation as validationSchema } from "../schemas";
import { GetProfilesForLoginService, LoginUserService } from "../Services";
import { Button } from "../Components/Inputs";

const WelcomePage = () => {
  const formik = useRef<FormikProps<FormParams>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { setProfiles, setMainProfileAndToken } = useContext(Context);
  const navigate = useNavigate();

  const submitForm = async (data: FormParams) => {
    setErrorMessage("");
    try {
      const { profiles, max_profiles } = await GetProfilesForLoginService(data);
      if (profiles.length === 0) {
        return;
      }
      setProfiles(profiles);
      if (profiles.length === 1 && profiles[0].pin_required !== 1) {
        const params = {
          ...data,
          profile_id: profiles[0].id,
        };
        const { profile, token } = await LoginUserService(params);
        setMainProfileAndToken(profile, token);
        navigate("/home");
      } else {
        navigate("/profiles", { state: data });
      }
    } catch (e: any) {
      let message = i18n.t("errors.somethingWrong");
      if (e.response.status === 400) {
        message = i18n.t("errors.invalidEmailOrPassword");
      }
      setErrorMessage(message);
    } finally {
      formik.current?.setSubmitting(false);
    }
  };

  return (
    <div className="welcome-page">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        innerRef={formik}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isValid,
          dirty,
          isSubmitting,
        }) => (
          <div className="onboarding-page">
            <div className="welcome-box">
              <h1 className="loginTitle">{i18n.t("titles.logIn")}</h1>
              <FormControl>
                <Stack mx="4" mb="4" alignItems={"center"}>
                  <FormControl.Label
                    alignSelf={"flex-start"}
                    mx="9"
                    _text={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "140%",
                      color: "#B5B2B0",
                    }}
                  >
                    Email
                  </FormControl.Label>
                  <Input
                    value={values.email}
                    color="#ffffff"
                    placeholderTextColor="#B5B2B0"
                    backgroundColor={"#272625"}
                    type="text"
                    borderColor={"#272625"}
                    borderWidth={"1px"}
                    placeholder={i18n.t("placeholders.email")}
                    onChangeText={handleChange("email")}
                    editable={!isSubmitting}
                    w="85%"
                    variant={"outline"}
                    focusOutlineColor={"#4F4C4A"}
                    _hover={{ borderColor: "#4F4C4A" }}
                    borderRadius={"8px"}
                    h={"48px"}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    fontSize={"16px"}
                    lineHeight={"140%"}
                  />
                </Stack>
              </FormControl>
              <FormControl isInvalid={errorMessage.length > 0}>
                <Stack mx="4" alignItems={"center"}>
                  <FormControl.Label
                    alignSelf={"flex-start"}
                    mx="9"
                    _text={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "140%",
                      color: "#B5B2B0",
                    }}
                  >
                    Password
                  </FormControl.Label>
                  <Input
                    value={values.password}
                    color="#ffffff"
                    placeholderTextColor="#B5B2B0"
                    type={showPassword ? "text" : "password"}
                    placeholder={i18n.t("placeholders.passwordYour")}
                    onChangeText={handleChange("password")}
                    editable={!isSubmitting}
                    w="85%"
                    variant={"filled"}
                    backgroundColor={"#272625"}
                    borderColor={"#272625"}
                    focusOutlineColor={"#4F4C4A"}
                    _hover={{ borderColor: "#4F4C4A" }}
                    borderRadius={"8px"}
                    h={"48px"}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    fontSize={"16px"}
                    lineHeight={"140%"}
                    mb={-1}
                    InputRightElement={
                      <div
                        className="hidePassword"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Icon
                          name={showPassword ? "eye-closed" : "eye-opened"}
                          size={5}
                          color={"#B5B2B0"}
                        />
                      </div>
                    }
                  />
                  {errorMessage.length > 1 && (
                    <FormControl.ErrorMessage
                      alignSelf={"flex-start"}
                      _text={{
                        color: "#F54141",
                        fontSize: 14,
                        fontWeight: "400",
                        lineHeight: "120%",
                      }}
                      mt={"8px"}
                      mb={-2}
                      mx={9}
                    >
                      {errorMessage}
                    </FormControl.ErrorMessage>
                  )}
                  <p className="password-helper-text">
                    {i18n.t("titles.forgotPassword")}
                  </p>
                </Stack>
              </FormControl>
              <Button
                type="submit"
                className="primary"
                disabled={!(isValid && dirty) || isSubmitting}
                label={i18n.t("titles.logIn")}
                handleSubmit={() => handleSubmit()}
              />
              <div className="account-create-box">
                <p className="grey-text">
                  {i18n.t("helpText.dontHaveAccount")}
                </p>
                <p className="gold-text">{i18n.t("actions.createAccount")}</p>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default WelcomePage;
