export function secondsToTime(e: number, display: 'time' | 'unit' = 'time') {
  const h = Math.floor(e / 3600),
    m = Math.floor((e % 3600) / 60),
    s = Math.floor(e % 60)

  if (display === 'time') {
    return `${h > 0 ? h + ':' : ''}${
      h > 0 ? m.toString().padStart(2, '0') : m
    }:${s.toString().padStart(2, '0')}`
  } else {
    const roundedM = s > 30 ? 1 : 0
    return `${h > 0 ? `${h}h${m > 0 ? ' ' : ''}` : ''}${m + roundedM}m`
  }
}

export function secondsToText(e: number) {
  const h = Math.floor(e / 3600)
  const m = Math.floor((e % 3600) / 60)

  return `${h > 0 ? h + ' h ' : ''}${
    h > 0 ? m.toString().padStart(2, '0') : m
  } min`
}
