import { api } from "../index";

interface Params {
  password: string;
  email: string;
  profile_id: number;
  pin?: string;
}

interface Response {
  token: string;
  profile: any;
}

export default (data: Params) => {
  return api.post("login", data).then((response) => {
    const res: Response = response.data;
    return res;
  });
};
