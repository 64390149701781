import React, { useEffect, useState } from "react";
import { getArtistNamesAsText } from "../../Helpers";
import "../../Cards.css";
import "../../App.css";
import { AlbumSkeleton } from "../Skeletons/Skeletons";
import { useNavigate } from "react-router-dom";

interface Props {
  item: any;
  imageClass?: string;
  offsetText?: boolean;
  isPlayList?: boolean;
}

const AlbumCard: React.FC<Props> = ({
  item,
  imageClass = "",
  offsetText = false,
  isPlayList = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const handleAlbumPress = () => {
    if (isPlayList) {
      navigate(`/playlist/${item.name}`, {
        state: {
          id: item.id,
          playlistData: item,
          content_type: item.content_type,
        },
      });
    } else {
      navigate(`/album/${item.name || item.collection.name}`, {
        state: {
          id: item.collection ? item.collection.id : item.id,
          showAllContent: true,
        },
      });
    }
  };

  useEffect(() => {
    if (item.photo.medium) {
      setIsLoaded(true);
    }
  }, [item]);

  return (
    <>
      {isLoaded ? (
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onClick={() => handleAlbumPress()}
        >
          <div className="card album-item">
            <img
              alt={item.name}
              id="image"
              src={item.photo.medium}
              className={`card album-image ${imageClass}`}
            />
          </div>
          <h1 className={`album-text ${offsetText && "offset-text"}`}>
            {item.title ? item.title : item.name || item.collection.name}
          </h1>
          {!isPlayList ? (
            <p
              className={`grey-text album-details ${
                offsetText && "offset-text"
              }`}
            >
              {`${getArtistNamesAsText(item.artist, item.other_artists ?? [])}`}
            </p>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                className={`grey-text album-details ${
                  offsetText && "offset-text"
                }`}
              >
                Playlist
              </p>
              <div className="dot-separator" />
              <p
                className={`grey-text album-details ${
                  offsetText && "offset-text"
                }`}
              >
                24Six
              </p>
            </div>
          )}
        </div>
      ) : (
        <AlbumSkeleton />
      )}
    </>
  );
};

export default AlbumCard;
