import { IIconProps } from "native-base";
import React from "react";

import {
  InfinityIcon,
  AddIcon,
  AddToPlaylistIcon,
  AirPlayIcon,
  AlbumIcon,
  AllowCircleIcon,
  AllowedRecommendIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArtistAllowedRecommendIcon,
  ArtistDontRecommendIcon,
  ArtistMusicIcon,
  ArtistVideoIcon,
  BurgerMenuIcon,
  CalendarIcon,
  CameraIcon,
  CancelCircleIcon,
  CastIcon,
  CategoryIcon,
  CheckIcon,
  CloseIcon,
  CloseSquareIcon,
  CopyIcon,
  CreditCardIcon,
  DatabaseIcon,
  DiamondIcon,
  DocumentIcon,
  DollarSignIcon,
  DontRecommendIcon,
  DoubleCheckIcon,
  DownloadIcon,
  DownloadedIcon,
  DuoIcon,
  EditIcon,
  EmailIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  FamilyIcon,
  FavoriteLightIcon,
  FeedbackIcon,
  FilterIcon,
  FolderAddIcon,
  FolderIcon,
  FolderRemoveIcon,
  FullScreenIcon,
  FullScreenTwoIcon,
  GamesIcon,
  GenderIcon,
  GlobeIcon,
  HourglassIcon,
  InfoIcon,
  ListIcon,
  LockIcon,
  LogOutIcon,
  LyricsIcon,
  MediaIcon,
  MicroIcon,
  MoreVerticalIcon,
  MoveIcon,
  MusicIcon,
  MusicTwoIcon,
  Next30Icon,
  NotificationIcon,
  PinIcon,
  PlayLastIcon,
  PlayLineIcon,
  PlayNextIcon,
  PlaylistIcon,
  PlaylistOldIcon,
  PlaylistVideoAddedIcon,
  PlaylistVideoIcon,
  PolicyIcon,
  Previews30Icon,
  QuestionIcon,
  ReduceSizeIcon,
  RepeatIcon,
  RepeatOneTimeIcon,
  ReportIcon,
  ResetIcon,
  SavedFolderIcon,
  ScaleSizeIcon,
  ScaleUpIcon,
  SearchIcon,
  SettingIcon,
  ShareIcon,
  ShieldIcon,
  ShowPodcastIcon,
  ShowVideoIcon,
  ShuffleIcon,
  SmallScreenIcon,
  SmartphoneIcon,
  SmsIcon,
  SortIcon,
  StarIcon,
  SuccessIcon,
  SwitchIcon,
  TileIcon,
  TimeIcon,
  TimerIcon,
  TrashIcon,
  UnsubscribeIcon,
  UserCircleIcon,
  UserIcon,
  VideoIcon,
  WifiOffIcon,
} from "./all";
import {
  AcousticGuitarIcon,
  BookOpenIcon,
  BriefCaseIcon,
  CarIcon,
  CoffeeIcon,
  ConcentrateIcon,
  DrinkLightIcon,
  FireIcon,
  HappyIcon,
  PowerIcon,
  RelaxIcon,
  SadIcon,
  WorkoutIcon,
} from "./categories";
import {
  AdultIcon,
  ApprovedIcon,
  BabyIcon,
  DrumIcon,
  FavoriteIcon,
  FemaleIcon,
  GuitarIcon,
  HeadPhonesIcon,
  LastPlayIcon,
  LockFillIcon,
  LyreIcon,
  MaleIcon,
  MaracaIcon,
  MicrophoneIcon,
  MusicFillIcon,
  NextPlayIcon,
  NotificationUnreadIcon,
  OlderChildIcon,
  PauseIcon,
  PianoIcon,
  PlayIcon,
  SettingFillIcon,
  SoundMinIcon,
  SoundMuteIcon,
  TileFillIcon,
  VideoFillIcon,
  WalletIcon,
  YoungerChildIcon,
} from "./fillIcons";

export enum IconNames {
  "arrow-down",
  "arrow-right",
  "arrow-up",
  "arrow-left",
  "notification",
  "music-2",
  "micro",
  "video",
  "folder",
  "folder-add",
  "folder-remove",
  "search",
  "music",
  "copy",
  "eye-closed",
  "eye-opened",
  "more-vertical",
  "air-play",
  "share",
  "download",
  "downloaded",
  "check",
  "shuffle",
  "sort",
  "repeat",
  "repeat-one-time",
  "lyrics",
  "artist-music",
  "artist-video",
  "artist-dont-recommend",
  "artist-allowed-recommend",
  "playlist",
  "playlist-old",
  "add-to-playlist",
  "dont-recommend",
  "allowed-recommend",
  "album",
  "show-video",
  "playlist-video",
  "playlist-video-added",
  "show-podcast",
  "play-line",
  "favorite-light",
  "wifi-off",
  "tile",
  "list",
  "move",
  "burger-menu",
  "play-next",
  "play-last",
  "unsubscribe",
  "email",
  "pin",
  "gender",
  "cast",
  "close",
  "question",
  "report",
  "setting",
  "reset",
  "filter",
  "full-screen",
  "small-screen",
  "full-screen-2",
  "scale-up",
  "double-check",
  "time",
  "add",
  "next-30",
  "previews-30",
  "info",
  "media",
  "diamond",
  "star",
  "hourglass",
  "dollar-sign",
  "user",
  "shield",
  "database",
  "credit-card",
  "smartphone",
  "globe",
  "policy",
  "feedback",
  "log-out",
  "duo",
  "family",
  "cancel-circle",
  "cancel-square",
  "allow-circle",
  "trash",
  "edit",
  "category",
  "timer",
  "calendar",
  "camera",
  "document",
  "user-circle",
  "infinity",
  "success",
  "games",
  "lock",
  "reduce-size",
  "scale-size",
  "twitter",
  "facebook",
  "linkedin",
  "viber",
  "instagram",
  "messenger",
  "telegram",
  "visa",
  "master-card",
  "whatsapp",
  "bosnia-herzegovina",
  "belgium",
  "belorus",
  "austria",
  "andorra",
  "albania",
  "usa",
  "uae",
  "uk",
  "israel",
  "canada",
  "tile-fill",
  "lock-fill",
  "sound-mute",
  "sound-min",
  "setting-fill",
  "music-fill",
  "wallet",
  "headphones",
  "guitar",
  "drum",
  "microphone",
  "lyre",
  "piano",
  "maraca",
  "adult",
  "older-child",
  "baby",
  "younger-child",
  "approved",
  "favorite",
  "last-play",
  "next-play",
  "play",
  "pause",
  "female",
  "male",
  "video-fill",
  "notification-unread",
  "car",
  "relax",
  "power",
  "concentrate",
  "book-open",
  "coffee",
  "brief-case",
  "happy",
  "sad",
  "fire",
  "drink-light",
  "workout",
  "acoustic-guitar",
  "switch-icon",
  "sms",
  "saved-folder",
}

export type IconNamesUnion = keyof typeof IconNames;

export interface IconProps extends Omit<IIconProps, "name" | "color"> {
  name: IconNamesUnion;
  color?: string;
  activeColor?: string;
}

export const Icon = React.memo(({ name, ...rest }: IconProps) => {
  switch (name) {
    case "arrow-down":
      return <ArrowDownIcon {...rest} />;
    case "arrow-right":
      return <ArrowRightIcon {...rest} />;
    case "arrow-up":
      return <ArrowUpIcon {...rest} />;
    case "arrow-left":
      return <ArrowLeftIcon {...rest} />;
    case "notification":
      return <NotificationIcon {...rest} />;
    case "music-2":
      return <MusicTwoIcon {...rest} />;
    case "micro":
      return <MicroIcon {...rest} />;
    case "video":
      return <VideoIcon {...rest} />;
    case "folder":
      return <FolderIcon {...rest} />;
    case "folder-add":
      return <FolderAddIcon {...rest} />;
    case "folder-remove":
      return <FolderRemoveIcon {...rest} />;
    case "saved-folder":
      return <SavedFolderIcon {...rest} />;
    case "search":
      return <SearchIcon {...rest} />;
    case "music":
      return <MusicIcon {...rest} />;
    case "copy":
      return <CopyIcon {...rest} />;
    case "eye-closed":
      return <EyeClosedIcon {...rest} />;
    case "eye-opened":
      return <EyeOpenedIcon {...rest} />;
    case "more-vertical":
      return <MoreVerticalIcon {...rest} />;
    case "air-play":
      return <AirPlayIcon {...rest} />;
    case "share":
      return <ShareIcon {...rest} />;
    case "download":
      return <DownloadIcon {...rest} />;
    case "downloaded":
      return <DownloadedIcon {...rest} />;
    case "sms":
      return <SmsIcon {...rest} />;
    case "check":
      return <CheckIcon {...rest} />;
    case "shuffle":
      return <ShuffleIcon {...rest} />;
    case "sort":
      return <SortIcon {...rest} />;
    case "repeat":
      return <RepeatIcon {...rest} />;
    case "repeat-one-time":
      return <RepeatOneTimeIcon {...rest} />;
    case "lyrics":
      return <LyricsIcon {...rest} />;
    case "artist-music":
      return <ArtistMusicIcon {...rest} />;
    case "artist-video":
      return <ArtistVideoIcon {...rest} />;
    case "artist-dont-recommend":
      return <ArtistDontRecommendIcon {...rest} />;
    case "artist-allowed-recommend":
      return <ArtistAllowedRecommendIcon {...rest} />;
    case "playlist":
      return <PlaylistIcon {...rest} />;
    case "playlist-old":
      return <PlaylistOldIcon {...rest} />;
    case "add-to-playlist":
      return <AddToPlaylistIcon {...rest} />;
    case "dont-recommend":
      return <DontRecommendIcon {...rest} />;
    case "allowed-recommend":
      return <AllowedRecommendIcon {...rest} />;
    case "album":
      return <AlbumIcon {...rest} />;
    case "show-video":
      return <ShowVideoIcon {...rest} />;
    case "playlist-video":
      return <PlaylistVideoIcon {...rest} />;
    case "playlist-video-added":
      return <PlaylistVideoAddedIcon {...rest} />;
    case "show-podcast":
      return <ShowPodcastIcon {...rest} />;
    case "play-line":
      return <PlayLineIcon {...rest} />;
    case "favorite-light":
      return <FavoriteLightIcon {...rest} />;
    case "wifi-off":
      return <WifiOffIcon {...rest} />;
    case "tile":
      return <TileIcon {...rest} />;
    case "list":
      return <ListIcon {...rest} />;
    case "move":
      return <MoveIcon {...rest} />;
    case "burger-menu":
      return <BurgerMenuIcon {...rest} />;
    case "play-next":
      return <PlayNextIcon {...rest} />;
    case "play-last":
      return <PlayLastIcon {...rest} />;
    case "unsubscribe":
      return <UnsubscribeIcon {...rest} />;
    case "email":
      return <EmailIcon {...rest} />;
    case "pin":
      return <PinIcon {...rest} />;
    case "gender":
      return <GenderIcon {...rest} />;
    case "cast":
      return <CastIcon {...rest} />;
    case "close":
      return <CloseIcon {...rest} />;
    case "question":
      return <QuestionIcon {...rest} />;
    case "report":
      return <ReportIcon {...rest} />;
    case "setting":
      return <SettingIcon {...rest} />;
    case "reset":
      return <ResetIcon {...rest} />;
    case "filter":
      return <FilterIcon {...rest} />;
    case "full-screen":
      return <FullScreenIcon {...rest} />;
    case "small-screen":
      return <SmallScreenIcon {...rest} />;
    case "full-screen-2":
      return <FullScreenTwoIcon {...rest} />;
    case "scale-up":
      return <ScaleUpIcon {...rest} />;
    case "double-check":
      return <DoubleCheckIcon {...rest} />;
    case "time":
      return <TimeIcon {...rest} />;
    case "add":
      return <AddIcon {...rest} />;
    case "next-30":
      return <Next30Icon {...rest} />;
    case "previews-30":
      return <Previews30Icon {...rest} />;
    case "info":
      return <InfoIcon {...rest} />;
    case "media":
      return <MediaIcon {...rest} />;
    case "diamond":
      return <DiamondIcon {...rest} />;
    case "star":
      return <StarIcon {...rest} />;
    case "hourglass":
      return <HourglassIcon {...rest} />;
    case "dollar-sign":
      return <DollarSignIcon {...rest} />;
    case "user":
      return <UserIcon {...rest} />;
    case "shield":
      return <ShieldIcon {...rest} />;
    case "database":
      return <DatabaseIcon {...rest} />;
    case "credit-card":
      return <CreditCardIcon {...rest} />;
    case "smartphone":
      return <SmartphoneIcon {...rest} />;
    case "globe":
      return <GlobeIcon {...rest} />;
    case "policy":
      return <PolicyIcon {...rest} />;
    case "feedback":
      return <FeedbackIcon {...rest} />;
    case "log-out":
      return <LogOutIcon {...rest} />;
    case "duo":
      return <DuoIcon {...rest} />;
    case "family":
      return <FamilyIcon {...rest} />;
    case "cancel-circle":
      return <CancelCircleIcon {...rest} />;
    case "cancel-square":
      return <CloseSquareIcon {...rest} />;
    case "allow-circle":
      return <AllowCircleIcon {...rest} />;
    case "trash":
      return <TrashIcon {...rest} />;
    case "edit":
      return <EditIcon {...rest} />;
    case "category":
      return <CategoryIcon {...rest} />;
    case "timer":
      return <TimerIcon {...rest} />;
    case "calendar":
      return <CalendarIcon {...rest} />;
    case "camera":
      return <CameraIcon {...rest} />;
    case "document":
      return <DocumentIcon {...rest} />;
    case "user-circle":
      return <UserCircleIcon {...rest} />;
    case "infinity":
      return <InfinityIcon {...rest} />;
    case "success":
      return <SuccessIcon {...rest} />;
    case "games":
      return <GamesIcon {...rest} />;
    case "lock":
      return <LockIcon {...rest} />;
    case "reduce-size":
      return <ReduceSizeIcon {...rest} />;
    case "scale-size":
      return <ScaleSizeIcon {...rest} />;
    case "tile-fill":
      return <TileFillIcon {...rest} />;
    case "lock-fill":
      return <LockFillIcon {...rest} />;
    case "sound-mute":
      return <SoundMuteIcon {...rest} />;
    case "sound-min":
      return <SoundMinIcon {...rest} />;
    case "setting-fill":
      return <SettingFillIcon {...rest} />;
    case "music-fill":
      return <MusicFillIcon {...rest} />;
    case "wallet":
      return <WalletIcon {...rest} />;
    case "headphones":
      return <HeadPhonesIcon {...rest} />;
    case "guitar":
      return <GuitarIcon {...rest} />;
    case "drum":
      return <DrumIcon {...rest} />;
    case "microphone":
      return <MicrophoneIcon {...rest} />;
    case "lyre":
      return <LyreIcon {...rest} />;
    case "piano":
      return <PianoIcon {...rest} />;
    case "maraca":
      return <MaracaIcon {...rest} />;
    case "adult":
      return <AdultIcon {...rest} />;
    case "older-child":
      return <OlderChildIcon {...rest} />;
    case "baby":
      return <BabyIcon {...rest} />;
    case "younger-child":
      return <YoungerChildIcon {...rest} />;
    case "approved":
      return <ApprovedIcon {...rest} />;
    case "favorite":
      return <FavoriteIcon {...rest} />;
    case "last-play":
      return <LastPlayIcon {...rest} />;
    case "next-play":
      return <NextPlayIcon {...rest} />;
    case "play":
      return <PlayIcon {...rest} />;
    case "pause":
      return <PauseIcon {...rest} />;
    case "female":
      return <FemaleIcon {...rest} />;
    case "male":
      return <MaleIcon {...rest} />;
    case "video-fill":
      return <VideoFillIcon {...rest} />;
    case "notification-unread":
      return <NotificationUnreadIcon {...rest} />;
    case "car":
      return <CarIcon {...rest} />;
    case "relax":
      return <RelaxIcon {...rest} />;
    case "power":
      return <PowerIcon {...rest} />;
    case "concentrate":
      return <ConcentrateIcon {...rest} />;
    case "book-open":
      return <BookOpenIcon {...rest} />;
    case "coffee":
      return <CoffeeIcon {...rest} />;
    case "brief-case":
      return <BriefCaseIcon {...rest} />;
    case "happy":
      return <HappyIcon {...rest} />;
    case "sad":
      return <SadIcon {...rest} />;
    case "fire":
      return <FireIcon {...rest} />;
    case "drink-light":
      return <DrinkLightIcon {...rest} />;
    case "workout":
      return <WorkoutIcon {...rest} />;
    case "acoustic-guitar":
      return <AcousticGuitarIcon {...rest} />;
    case "switch-icon":
      return <SwitchIcon {...rest} />;
    default:
      return null;
  }
});
