import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import i18n from "../../Translations";
import { useNavigate } from "react-router-dom";
import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_SUGGESTED_SUFFIX,
} from "../../Constants";

const FeaturedSongs: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
  followedIds = [],
}) => {
  const navigate = useNavigate();

  const viewAllAction = () => {
    navigate("/content/featured-songs", {
      state: {
        index: `${ALGOLIA_CONTENTS_INDEX_NAME}${ALGOLIA_SUGGESTED_SUFFIX}`,
        content_type: "music",
        title: i18n.t("titles.featuredForYou"),
        facetFilters: ["content_type:music"],
        contentDisplay: "album",
        initialData: collection,
        otherOptions: {
          optionalFilters: followedIds.map((id: any) => `artist.id:${id}`),
          sumOrFiltersScores: true,
        },
      },
    });
  };
  return (
    <div className="categories-margin">
      {collection.length ? (
        <ScrollableList
          itemClassName={`card-container ${collection.length > 0 && "hover"}`}
          title={i18n.t("titles.featuredForYou")}
          viewAllAction={viewAllAction}
        >
          {collection.map((item: any) => {
            return (
              <div key={item.id}>
                <AlbumCard item={item} />
              </div>
            );
          })}
        </ScrollableList>
      ) : null}
    </div>
  );
};

export default FeaturedSongs;
