import { tableSchema } from '@nozbe/watermelondb'

export default tableSchema({
  name: 'content_collections',
  columns: [
    { name: 'algolia_id', type: 'number', isIndexed: true },
    { name: 'name', type: 'string' },
    { name: 'content_type', type: 'string' },
    { name: 'photo', type: 'string' },
    { name: 'popularity_score', type: 'number' },
    { name: 'artist', type: 'string' },
    { name: 'other_artists', type: 'string' },
    { name: 'contents', type: 'string' },
    { name: 'tags', type: 'string' },
    { name: 'flags', type: 'string' },
    { name: 'release_date', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ],
})
