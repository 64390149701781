import React, { useCallback, useContext, useEffect, useState } from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import { useLocation } from "react-router-dom";
import { algoliaSearch } from "../../Services";
import { Context } from "../../Context/Context";
import { uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import ArtistCard from "../../Components/Cards/ArtistCard";

const ContentPage: React.FC<any> = () => {
  const { state } = useLocation();
  const { mainProfile } = useContext(Context);

  const contentDisplay = state?.contentDisplay;
  const attributesToRetrieve = state?.attributesToRetrieve;
  const facetFilters = state?.facetFilters;
  const algoliaOtherOptions = state?.otherOptions || {};
  const algolia_index = state?.index;
  const content_type = state?.content_type;
  const title = state?.title;
  const initialData = state?.initialData ?? [];

  const [isLoading, setIsLoading] = useState(!initialData.length);
  const [contents, setContents] = useState(initialData);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const enableProfileFilters =
    typeof state?.enableProfileFilters !== "undefined"
      ? state?.enableProfileFilters
      : true;
  const nullCache = state?.nullCache;

  useEffect(() => {
    getContents().then(() => {
      setIsLoading(false);
    });
  }, []);

  const getContents = async (page = 0) => {
    try {
      const res = await algoliaSearch({
        selectedIndex: algolia_index,
        additionalOptions: {
          attributesToRetrieve: attributesToRetrieve || undefined,
          facetFilters: facetFilters,
          hitsPerPage: 25,
          offset: 0,
          ...algoliaOtherOptions,
          page,
        },
        enableProfileFilters,
        nullCache,
        profile: mainProfile,
      });

      const tracks = res?.hits;
      //   contentDisplay === 'episode-card'
      //     ? res.hits.map(item => {
      //         const isPlayed = played.indexOf(item?.id) !== -1
      //         return {
      //           ...item,
      //           isPlayed,
      //         }
      //       })
      //     : res.hits

      setContents((prevContents: any) => {
        if (page > 0 || prevContents.length) {
          return uniqBy([...prevContents, ...tracks], "id");
        }

        return tracks;
      });

      setNumberOfPages(res.nbPages);
    } catch {
      console.log("error");
      //   showSnackbar({
      //     type: 'error',
      //     title: i18n.t('errors.algoliaGeneralError'),
      //   })
    }
  };

  const getMoreContents = useCallback(() => {
    if (isLoading) {
      return;
    }

    const newCurrentPage = currentPage + 1;
    if (newCurrentPage >= numberOfPages) {
      // this means we have already fetched all the available pages
      return;
    }

    getContents(newCurrentPage);
    setCurrentPage(newCurrentPage);
  }, [currentPage, numberOfPages, isLoading]);

  const handleScrollLoadMore = (e: any) => {
    const isBottom =
      e.target.scrollHeight - e.target.scrollTop ===
      e.target.clientHeight + 0.5;
    if (isBottom && currentPage + 1 <= numberOfPages) {
      getMoreContents();
    }
  };

  return (
    <div className="content-screen">
      <div className="title-container">
        <h1 className="content-title">{title}</h1>
      </div>
      <InfiniteScroll
        className="content-container"
        dataLength={contents.length} //This is important field to render the next data
        next={getMoreContents}
        loader={<div></div>}
        hasMore={currentPage + 1 <= numberOfPages}
      >
        {contents.length
          ? contents.map((item: any) => {
              let renderItem;
              if (contentDisplay === "album") {
                renderItem = <AlbumCard item={item} offsetText />;
              } else if (contentDisplay === "artist") {
                renderItem = <ArtistCard item={item} />;
              }
              return (
                <div
                  key={item.id}
                  className={`card-container ${contents.length > 0 && "hover"}`}
                >
                  {renderItem}
                </div>
              );
            })
          : null}
      </InfiniteScroll>
    </div>
  );
};

export default ContentPage;
