import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Cards.css";
import { ArtistSkeleton } from "../Skeletons/Skeletons";

interface Props {
  item: any;
  imageClass?: string;
}

const ArtistCard: React.FC<Props> = ({ item }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const handleOnPress = () => {
    navigate(`/artist/${item.display_name}`, {
      state: {
        id: item.id,
      },
    });
  };

  useEffect(() => {
    if (item.photo.medium) {
      setIsLoaded(true);
    }
  }, [item]);

  return (
    <>
      {isLoaded ? (
        <div className="artist-item" key={item.id} onClick={handleOnPress}>
          <img
            alt={item.display_name}
            id="artist-item"
            src={item.photo.medium}
            className="artist-image"
            onLoad={() => setIsLoaded(true)}
          />
          <h1 className="artist-text">{item.display_name}</h1>
        </div>
      ) : (
        <ArtistSkeleton />
      )}
    </>
  );
};

export default ArtistCard;
