import { useState } from "react";
import { generatePermissionFilterObject } from "../Helpers";
import { ProfileModel } from "../Models";
import { api } from "../Services";
import { clearedPermissionFilterState } from "../Services/Algolia/PermissionStates";
import calculateAge from "../Services/utils/calculateAge";
import { Context } from "./MapContext";

interface Props {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [profiles, setProfiles] = useState<any>([]);
  const [mainProfile, setMainProfile] = useState<any>({});
  const [token, setToken] = useState<string>("");

  const generatePermissionSettings = (profile: ProfileModel) => {
    const permissions = profile
      ? generatePermissionFilterObject(
          profile.permission,
          //@ts-ignore
          clearedPermissionFilterState
        )
      : clearedPermissionFilterState;
    const dateOfBirth = calculateAge(profile?.date_of_birth);
    const isAdult = dateOfBirth ? dateOfBirth < 18 : false;
    return {
      permissionFilterSettings: !profile
        ? null
        : {
            ...permissions,
            music: profile.permission?.music ?? "all",
            video: profile.permission?.video ?? "all",
            podcast: profile.permission?.podcasts ?? "all",
            blockedKolIsha: {
              ...clearedPermissionFilterState.blockedKolIsha,
              algoliaQueries: {
                ...clearedPermissionFilterState.blockedKolIsha.algoliaQueries,
                flagsKolIsha: "flags.kol_isha:false",
              },
              isBlocked: profile?.gender === "m",
            },
            adultOnly: {
              ...clearedPermissionFilterState.adultOnly,
              algoliaQueries: {
                ...clearedPermissionFilterState.adultOnly.algoliaQueries,
                flagsAdultOnly: "flags.adult_only:false",
              },
              isBlocked: isAdult,
            },
          },
    };
  };

  const setBearerToken = (token: string) => {
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
  };

  const setMainProfileAndToken = (profile: ProfileModel, token: string) => {
    setMainProfile({ ...profile, ...generatePermissionSettings(profile) });
    setBearerToken(token);
    setToken(token);
  };

  return (
    <Context.Provider
      value={{
        profiles,
        mainProfile,
        token,
        setProfiles,
        setMainProfile,
        setToken,
        setMainProfileAndToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};
