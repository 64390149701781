import { SearchOptions } from "@algolia/client-search";

import { ALGOLIA_CONTENTS_INDEX_NAME } from "../../Constants";
import { SongModel } from "../../Models";

import { algoliaSearch } from ".";

export const GetArtistSongs = (
  artistId: number,
  options?: SearchOptions,
  profile?: any
) => {
  const contentType = "music";
  return algoliaSearch<SongModel>({
    selectedIndex: ALGOLIA_CONTENTS_INDEX_NAME,
    additionalOptions: {
      facetFilters: [`artist.id:${artistId}`, `content_type:${contentType}`],
      ...options,
    },
    profile: profile,
  });
};
