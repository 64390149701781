import React from "react";
import "../../Cards.css";
import "../../App.css";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import GenreCard from "../../Components/Cards/GenreCard";
import i18n from "../../Translations";
import { useNavigate } from "react-router-dom";

const GenresList: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
}) => {
  const navigate = useNavigate();

  const viewAllAction = () => {
    navigate("/categories", {
      state: { content_type: "music", categories: collection },
    });
  };

  return (
    <div>
      {collection.length ? (
        <ScrollableList
          itemClassName={`genre-item`}
          title={i18n.t("titles.genres")}
          viewAllAction={viewAllAction}
        >
          {collection.map((item: any) => {
            return (
              <div key={item.id}>
                <GenreCard item={item} />
              </div>
            );
          })}
        </ScrollableList>
      ) : null}
    </div>
  );
};

export default GenresList;
