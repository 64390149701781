import { Q as Query } from "@nozbe/watermelondb";
import _ from "lodash";

import {
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_SUGGESTED_SUFFIX,
} from "../../Constants";
import { ContentItemModel } from "../../Models";
import { databaseGetMultipleData, hasFreshData } from "../../Services/Database";
import { ContentTypes } from "../../Types";

import { algoliaSearch } from ".";

export const GetTrendingContent = async (
  type: ContentTypes,
  ignoreCache: boolean,
  profile: any
) => {
  if (!ignoreCache && (await hasFreshData(ALGOLIA_CONTENTS_INDEX_NAME))) {
    return databaseGetMultipleData<ContentItemModel[]>(
      ALGOLIA_CONTENTS_INDEX_NAME,
      profile,
      15,
      Query.sortBy("popularity_score", Query.desc)
    ).then((songs: any) => {
      return {
        data: _.uniqBy(songs, "id").slice(0, 15),
        shouldSave: false,
      };
    });
  }

  return algoliaSearch<ContentItemModel>({
    selectedIndex: `${ALGOLIA_CONTENTS_INDEX_NAME}${ALGOLIA_SUGGESTED_SUFFIX}`,
    nullCache: ignoreCache,
    additionalOptions: {
      facetFilters: `content_type:${type}`,
      length: 15,
      offset: 0,
    },
    profile,
  }).then(({ hits }) => {
    return {
      data: hits,
      shouldSave: true,
    };
  });
};
