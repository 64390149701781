import { createNullCache } from "@algolia/cache-common";
import { SearchOptions } from "@algolia/client-search";
import algoliarecommend, { RecommendSearchOptions } from "@algolia/recommend";
import algoliasearch from "algoliasearch";
import { pick } from "lodash";
import {
  ALGOLIA_ARTISTS_INDEX_NAME,
  ALGOLIA_CONTENTS_INDEX_NAME,
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  ALGOLIA_INDICES_VALUES,
} from "../../Constants/Algolia";
import { ProfileModel } from "../../Models";
import { AlgoliaIndices } from "../../Types";

import { AlgoliaReplicaIndices } from "./../../Types/Algolia";

interface AlgoliaGetProps {
  id: number;
  selectedIndex: AlgoliaIndices;
  attributes?: Array<string>;
  disableKolIshaFilter?: boolean;
  overrideUserKolIshaFilter?: boolean;
  blockArtistById?: boolean;
  hideFutureReleases?: boolean;
  profile?: any;
}

interface AlgoliaObjectProps {
  indexName: AlgoliaIndices;
  objectID: string;
}

interface AlgoliaRelatedProps {
  id: number;
  selectedIndex: AlgoliaIndices;
  maxRecommendations?: number;
  queryParameters?: RecommendSearchOptions;
  blockArtistById?: boolean;
  enableProfileFilters?: boolean;
  profile?: any;
}

export const getFilterFlag = (
  index: AlgoliaIndices,
  disableKolIshaFilter?: boolean,
  overrideUserKolIshaFilter?: boolean,
  profile?: any
) => {
  const {
    blockedKolIsha: {
      algoliaQueries: { flagsKolIsha },
      isBlocked: isBlockedKolIsa,
    },
    adultOnly: {
      algoliaQueries: { flagsAdultOnly },
      isBlocked: isBlockedAdultOnly,
    },
    //@ts-ignore
  } = profile?.permissionFilterSettings;

  const isBlocked: boolean = disableKolIshaFilter
    ? !disableKolIshaFilter
    : overrideUserKolIshaFilter || isBlockedKolIsa;

  // TODO add other_artists.${flagsKolIsha} for empty array boolean attribute
  const artistKolIshaFilters = index.includes("content")
    ? `AND (artist.${flagsKolIsha}) AND (other_artists.${flagsKolIsha} OR NOT other_artists.${flagsKolIsha})`
    : "";

  const collectionFilters: string = index.includes("contents")
    ? isBlocked
      ? isBlockedAdultOnly
        ? ` AND (collection.${flagsKolIsha}) AND (collection.${flagsAdultOnly})`
        : ` AND (collection.${flagsKolIsha})`
      : isBlockedAdultOnly
      ? ` AND (collection.${flagsAdultOnly})`
      : ""
    : "";

  const filterKolIsha: string = isBlocked
    ? artistKolIshaFilters
      ? `${flagsKolIsha} ${artistKolIshaFilters}`
      : flagsKolIsha
    : "";

  const filterAdultOnly: string =
    isBlockedAdultOnly && index.includes("content") ? flagsAdultOnly : "";

  const filters = filterKolIsha
    ? filterAdultOnly
      ? `${filterKolIsha} AND ${filterAdultOnly}`
      : filterKolIsha
    : filterAdultOnly
    ? filterAdultOnly
    : "";
  return filters + collectionFilters;
};

export const getFutureReleasesFilter = (
  index: AlgoliaIndices | AlgoliaReplicaIndices,
  hideFutureReleases: boolean = true
) => {
  if (!hideFutureReleases) {
    return "";
  }

  if (index.includes(ALGOLIA_CONTENTS_INDEX_NAME)) {
    return `upload_date_timestamp <= ${Math.floor(Date.now() / 1000)}`;
  } else if (index.includes(ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME)) {
    return `release_date_timestamp <= ${Math.floor(Date.now() / 1000)}`;
  } else {
    return "";
  }
};

export const getAutoFilters = ({
  index,
  hideFutureReleases = true,
  disableKolIshaFilter = false,
  overrideUserKolIshaFilter = false,
  profile = {},
}: {
  index: AlgoliaIndices | AlgoliaReplicaIndices;
  hideFutureReleases?: boolean;
  disableKolIshaFilter?: boolean;
  overrideUserKolIshaFilter?: boolean;
  profile?: any;
}): String => {
  const storedFilter = getFilterFlag(
    //@ts-ignore
    index,
    disableKolIshaFilter,
    overrideUserKolIshaFilter,
    profile
  );

  const futureReleasesFilter = getFutureReleasesFilter(
    index,
    hideFutureReleases
  );

  return `${storedFilter}${
    storedFilter && futureReleasesFilter ? " AND " : ""
  }${futureReleasesFilter}`;
};

const client = algoliasearch(
  //@ts-ignore
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY
);

const clientNullCache = algoliasearch(
  //@ts-ignore
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY,
  {
    responsesCache: createNullCache(),
  }
);

const clientRec = algoliarecommend(
  //@ts-ignore
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY
);

function buildAlgoliaFilterString(
  {
    blockArtistById = false,
  }: {
    blockArtistById?: boolean;
  },
  profile?: any
) {
  //@ts-ignore

  let filterString = profile?.permissionFilterSettings?.filterString ?? "";

  if (blockArtistById) {
    //filters by the id field, for artists
    const categoriesQuery =
      //@ts-ignore

      profile?.permissionFilterSettings?.blockedCategories?.algoliaQueries
        .categoriesCategoryId || "";

    const blockedArtistIdString =
      //@ts-ignore

      profile?.permissionFilterSettings?.blockedArtists.algoliaQueries.id || "";

    const blockedOtherArtistIdString =
      //@ts-ignore

      profile?.permissionFilterSettings?.blockedArtists.algoliaQueries
        .otherArtistsIds || "";

    filterString = `${categoriesQuery}${
      categoriesQuery && blockedArtistIdString ? " AND " : ""
    }${blockedArtistIdString}${
      blockedArtistIdString && blockedOtherArtistIdString ? " AND " : ""
    }${blockedOtherArtistIdString}`;
  }

  return filterString;
}

function getPrependValue(selectedIndex: AlgoliaIndices) {
  return ALGOLIA_INDICES_VALUES[selectedIndex];
}

function getQueryString(ids: Array<string | number>) {
  const idsArr = ids.map((id, _i) => {
    return _i === 0 ? `id:${id}` : `OR id:${id}`;
  });
  return idsArr.join(" ");
}

export function algoliaGetSingleObject<returnType>({
  id,
  selectedIndex,
  attributes,
  disableKolIshaFilter = false,
  blockArtistById = true,
  hideFutureReleases = true,
  profile = {},
}: AlgoliaGetProps) {
  const index = client.initIndex(selectedIndex);
  const autoFilters = getAutoFilters({
    index: selectedIndex,
    disableKolIshaFilter,
    hideFutureReleases,
    profile: profile,
  });
  const filters = autoFilters ? `id:${id} AND ${autoFilters}` : `id:${id}`;

  const filterString = buildAlgoliaFilterString(
    {
      blockArtistById:
        selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) && blockArtistById,
    },
    profile
  );

  const options =
    attributes?.length && attributes[0]
      ? { filters, facetFilters: attributes }
      : { filters };

  if (filterString) {
    options["filters"] = options["filters"]
      ? `${options["filters"]} AND ${filterString}`
      : filterString;
  }

  return index.search<returnType>("", options).then(({ hits }) => hits[0]);
}

export function algoliaGetMultipleById<returnType>({
  ids,
  selectedIndex,
  disableKolIshaFilter = false,
  blockArtistById = true,
  hideFutureReleases = true,
  overrideUserKolIshaFilter = false,
  enableProfileFilters = true,
  profile,
}: {
  ids: Array<string | number>;
  selectedIndex: AlgoliaIndices;
  disableKolIshaFilter?: boolean;
  blockArtistById?: boolean;
  hideFutureReleases?: boolean;
  overrideUserKolIshaFilter?: boolean;
  enableProfileFilters?: boolean;
  profile?: any;
}) {
  const index = client.initIndex(selectedIndex);
  const queryString = getQueryString(ids);
  const autoFilters = enableProfileFilters
    ? getAutoFilters({
        index: selectedIndex,
        disableKolIshaFilter,
        hideFutureReleases,
        overrideUserKolIshaFilter,
        profile,
      })
    : getFutureReleasesFilter(selectedIndex, hideFutureReleases);

  const filters = autoFilters
    ? `${queryString ? `(${queryString})` : ""}${
        queryString && autoFilters ? " AND " : ""
      }${autoFilters}`
    : queryString;

  const filterString = enableProfileFilters
    ? buildAlgoliaFilterString(
        {
          blockArtistById:
            selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) &&
            blockArtistById,
        },
        profile
      )
    : "";
  // const autoFilters = getAutoFilters({
  //   index: selectedIndex,
  //   disableKolIshaFilter,
  //   hideFutureReleases,
  //   profile: profile,
  // });
  // const filters = autoFilters
  //   ? `(${queryString}) AND ${autoFilters}`
  //   : queryString;

  // const filterString = buildAlgoliaFilterString(
  //   {
  //     blockArtistById:
  //       selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) && blockArtistById,
  //   },
  //   profile
  // );

  const options = { filters, hitsPerPage: ids.length };

  if (filterString) {
    options["filters"] = options["filters"]
      ? `${options["filters"]} AND ${filterString}`
      : filterString;
  }

  return index.search<returnType>("", options).then(({ hits }) => {
    const results = ids
      //@ts-ignore
      .map((id) => hits.find((item) => item.id === id))
      .filter((el) => el !== undefined);
    return { results };
  });
}

export function algoliaGetMultipleByIdWithPagination<returnType>({
  ids,
  selectedIndex,
  disableKolIshaFilter = false,
  blockArtistById = true,
  hitsPerPage,
  page,
  hideFutureReleases = true,
  profile = {},
}: {
  ids: Array<string | number>;
  selectedIndex: AlgoliaIndices;
  disableKolIshaFilter?: boolean;
  blockArtistById?: boolean;
  hitsPerPage?: number;
  page?: number;
  hideFutureReleases?: boolean;
  profile?: any;
}) {
  const index = client.initIndex(selectedIndex);
  const queryString = getQueryString(ids);
  const autoFilters = getAutoFilters({
    index: selectedIndex,
    disableKolIshaFilter,
    hideFutureReleases,
  });
  const filters = autoFilters
    ? `(${queryString}) AND ${autoFilters}`
    : queryString;

  const filterString = buildAlgoliaFilterString(
    {
      blockArtistById:
        selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) && blockArtistById,
    },
    profile
  );

  const options: any = { filters, hitsPerPage: ids.length };

  if (page) {
    options["page"] = page;
  }
  if (hitsPerPage) {
    options["hitsPerPage"] = hitsPerPage;
  }

  if (filterString) {
    options["filters"] = options["filters"]
      ? `${options["filters"]} AND ${filterString}`
      : filterString;
  }
  return index.search<returnType>("", options).then(({ hits, ...rest }) => {
    return {
      results: hits,
      ...pick(rest, ["hitsPerPage", "nbHits", "nbPages", "page"]),
    };
  });
}

export function algoliaGetMultipleDifferentObjects<returnType>(
  objects: AlgoliaObjectProps[]
) {
  const idsWithPrepend = objects.map((o: AlgoliaObjectProps) => {
    let newObj = { ...o };
    const prependValue = getPrependValue(o.indexName);
    if (prependValue && !o.objectID.toString().includes(prependValue)) {
      newObj.objectID = prependValue + o.objectID;
    }
    return newObj;
  });
  return client.multipleGetObjects<returnType>(idsWithPrepend);
}

export async function algoliaGetRelated<returnType>({
  id,
  selectedIndex,
  maxRecommendations = 0,
  queryParameters = {},
  blockArtistById = true,
  enableProfileFilters = true,
  profile = {},
}: AlgoliaRelatedProps) {
  const prependValue = getPrependValue(selectedIndex);
  const autoFilters = getAutoFilters({
    index: selectedIndex,
    profile: profile,
  });
  const filters = autoFilters
    ? queryParameters?.filters
      ? queryParameters?.filters + ` AND ${autoFilters}`
      : autoFilters
    : "";

  let options = filters ? { ...queryParameters, filters } : queryParameters;

  if (enableProfileFilters) {
    let filterString = buildAlgoliaFilterString(
      {
        blockArtistById:
          selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) && blockArtistById,
      },
      profile
    );

    if (filterString) {
      //@ts-ignore
      options["filters"] = options["filters"]
        ? `${options["filters"]} AND ${filterString}`
        : filterString;
    }
  }

  return clientRec.getRelatedProducts<returnType>([
    {
      indexName: selectedIndex,
      objectID: prependValue + id,
      maxRecommendations,
      //@ts-ignore
      queryParameters: options,
    },
  ]);
}

export async function algoliaSearch<returnType>({
  additionalOptions,
  query = "",
  selectedIndex,
  blockArtistById = true,
  enableProfileFilters = true,
  disableKolIshaFilter = false,
  nullCache,
  overrideUserKolIshaFilter = false,
  hideFutureReleases = true,
  profile = {},
}: {
  additionalOptions?: SearchOptions;
  query?: string;
  selectedIndex: AlgoliaIndices | AlgoliaReplicaIndices;
  blockArtistById?: boolean;
  enableProfileFilters?: boolean;
  disableKolIshaFilter?: boolean;
  nullCache?: boolean;
  overrideUserKolIshaFilter?: boolean;
  hideFutureReleases?: boolean;
  profile?: any;
}) {
  const index = nullCache
    ? clientNullCache.initIndex(selectedIndex)
    : client.initIndex(selectedIndex);

  const autoFilters = getAutoFilters({
    index: selectedIndex,
    disableKolIshaFilter,
    overrideUserKolIshaFilter,
    hideFutureReleases,
    profile,
  });

  const filters = autoFilters
    ? additionalOptions?.filters
      ? additionalOptions?.filters + ` AND ${autoFilters}`
      : autoFilters
    : "";

  let options = filters
    ? { ...additionalOptions, filters }
    : additionalOptions
    ? additionalOptions
    : {};

  if (enableProfileFilters) {
    let filterString = buildAlgoliaFilterString(
      {
        blockArtistById:
          selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) && blockArtistById,
      },
      profile
    );

    if (filterString) {
      //@ts-ignore
      options["filters"] = options["filters"]
        ? `${options["filters"]} AND ${filterString}`
        : filterString;
    }
  }
  //@ts-ignore
  return index.search<returnType>(query, options);
}

interface MultipleIndicesProps {
  selectedIndex: AlgoliaIndices;
  additionalOptions?: SearchOptions;
}

export async function algoliaSearchMultipleIndices<returnType>({
  searchQuery = "",
  indices,
  blockArtistById = true,
  enableProfileFilters = true,
  disableKolIshaFilter = false,
  page = 0,
  hideFutureReleases = true,
  profile = {},
}: {
  searchQuery?: string;
  indices: Array<MultipleIndicesProps>;
  blockArtistById?: boolean;
  enableProfileFilters?: boolean;
  disableKolIshaFilter?: boolean;
  page: number | null;
  hideFutureReleases?: boolean;
  profile?: any;
}) {
  const queries = indices.map((i) => {
    const autoFilters = getAutoFilters({
      index: i.selectedIndex,
      disableKolIshaFilter,
      hideFutureReleases,
    });
    const filters = autoFilters
      ? i.additionalOptions?.filters
        ? i.additionalOptions?.filters + ` AND ${autoFilters}`
        : autoFilters
      : "";

    let options = filters
      ? { ...i.additionalOptions, filters }
      : i.additionalOptions
      ? i.additionalOptions
      : {};

    if (enableProfileFilters) {
      let filterString = buildAlgoliaFilterString(
        {
          blockArtistById:
            i.selectedIndex.includes(ALGOLIA_ARTISTS_INDEX_NAME) &&
            blockArtistById,
        },
        profile
      );

      if (filterString) {
        //@ts-ignore
        options["filters"] = options["filters"]
          ? `${options["filters"]} AND ${filterString}`
          : filterString;
      }
    }

    if (page !== null) {
      //@ts-ignore
      options["page"] = page;
    }

    return {
      indexName: i.selectedIndex,
      query: searchQuery,
      params: options,
    };
  });
  //@ts-ignore
  return client.multipleQueries(queries);
}
