import i18n from "../Translations";
import "../ContentContainers.css";
import "../App.css";
import { ArrowRightIcon } from "./Icons/all";

const ArtistBio = ({ photo, bio, displayName }: any) => {
  return (
    <div className="bio-container">
      <div className="flex bio-header">
        <h1>{i18n.t("artist.main.about")}</h1>
        <p className="pointer">{i18n.t("titles.readMore")}</p>
      </div>
      <div className="bio-card">
        <div>
          <img src={photo} alt="" className="bio-photo" />
          <div className="bio-info flex grey-hover pointer">
            <div className="flex bio-subheader-container">
              <h1 className="artist-name">{displayName}</h1>

              <p className="artist-bio subheader grey-text">{bio}</p>
            </div>
            <div className="flex center-content">
              <ArrowRightIcon height={18} width={18} color={"#b5b2b0"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistBio;
