import React, { useCallback, useEffect, useState } from "react";
import { getArtistNamesAsText } from "../Helpers";
import { GetAlbumData, IContent } from "../Services/Algolia/GetAlbumData";
import i18n from "../Translations";
import "../ContentContainers.css";
import "../App.css";
import { useNavigate } from "react-router-dom";

interface Props {
  artistId: number;
  profile: any;
}

export const LatestRelease = React.memo(({ artistId, profile }: Props) => {
  const navigate = useNavigate();
  const [content, setContent] = useState<IContent>();

  const navigateAlbum = useCallback(() => {
    navigate(`/album/${content?.name}`, {
      state: { id: content?.id },
    });
  }, [content?.id]);

  useEffect(() => {
    GetAlbumData(artistId, "music", { length: 1, offset: 0 }, profile)
      .then((res) => {
        if (res.albums) {
          //@ts-ignore
          setContent(res.albums[0]);
        }
      })
      .catch(() => {});
  }, [artistId]);

  if (!content) {
    return null;
  }

  const description = `${getArtistNamesAsText(
    content.artist
    // content.other_artists,
  )}${content.artist && content.release_date ? " • " : ""}${
    content.release_date ? new Date(content.release_date).getFullYear() : ""
  }`;

  return (
    <div className="latest-container flex" onClick={navigateAlbum}>
      <div className="flex latest-item grey-hover">
        <img
          alt={content.name}
          id="latest-image"
          width={150}
          height={150}
          style={{
            borderRadius: "8px",
            marginLeft: "20px",
            marginRight: "16px",
          }}
          src={content.photo.medium}
          className="latest-image"
        />
        <div>
          <h1 className="latest-title">
            {i18n.t("artist.main.latestRelease")}
          </h1>
          <p className="subheader subheader-name">{content.name}</p>
          <p className="albumdetails-text grey-text subheader-description">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
});
