import _ from "lodash";

import { ALGOLIA_CONTENTS_INDEX_NAME } from "../../Constants";
import { ContentItemModel, SongModel } from "../../Models";
import { GetSuggestedContent } from "../../Services/Algolia/GetSuggestedContent";
import {
  databaseCreateMultipleData,
  databaseGetMultipleData,
  hasFreshData,
} from "../../Services/Database";

export const GetFeaturedContent = async (
  followIds: number[],
  ignoreCache: boolean,
  profile: any
) => {
  const RESULT_AMOUNT = 30;
  const DISPLAYED_AMOUNT = 15;

  if (
    !ignoreCache &&
    (await hasFreshData(ALGOLIA_CONTENTS_INDEX_NAME, undefined, RESULT_AMOUNT))
  ) {
    return databaseGetMultipleData<ContentItemModel[]>(
      ALGOLIA_CONTENTS_INDEX_NAME,
      profile,
      RESULT_AMOUNT
    ).then((songs: any) => {
      const shuffledSongs = songs.sort(() => Math.random() - 0.5);

      if (_.isEmpty(followIds)) return _.take(shuffledSongs, DISPLAYED_AMOUNT);

      const filteredSongs = shuffledSongs.filter((song: any) =>
        followIds.includes(song.artist?.id ?? NaN)
      );

      if (filteredSongs.length >= DISPLAYED_AMOUNT) {
        return _.take(filteredSongs, DISPLAYED_AMOUNT);
      }

      const filteredShuffledSongs = _.take(
        _.uniqBy(filteredSongs.concat(shuffledSongs), "id"),
        DISPLAYED_AMOUNT
      );

      return filteredShuffledSongs;
    });
  } else {
    return GetSuggestedContent(followIds ?? [], RESULT_AMOUNT, profile).then(
      (contents) => {
        databaseCreateMultipleData(
          ALGOLIA_CONTENTS_INDEX_NAME,
          contents as never as SongModel[]
        );
        return _.shuffle(contents).slice(0, DISPLAYED_AMOUNT);
      }
    );
  }
};
