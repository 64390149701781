import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import i18n from "../../Translations";
import { useNavigate } from "react-router-dom";
import {
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  ALGOLIA_DESCENDING_SUFFIX,
} from "../../Constants";

const TrendingNow: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
}) => {
  const navigate = useNavigate();

  const viewAllAction = () => {
    navigate("/content/trending", {
      state: {
        index: `${ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME}${ALGOLIA_DESCENDING_SUFFIX}`,
        content_type: "music",
        title: i18n.t("titles.trendingNow"),
        facetFilters: ["content_type:music"],
        contentDisplay: "album",
        nullCache: true,
        initialData: collection,
        attributesToRetrieve: [
          "id",
          "name",
          "photo",
          "artist",
          "contents",
          "release_date",
        ],
      },
    });
  };

  return (
    <div>
      {collection.length ? (
        <ScrollableList
          itemClassName={`card-container ${collection.length > 0 && "hover"}`}
          title={i18n.t("titles.trendingNow")}
          viewAllAction={viewAllAction}
        >
          {collection.map((item: any) => {
            return (
              <div key={item.id}>
                <AlbumCard item={item} />
              </div>
            );
          })}
        </ScrollableList>
      ) : null}
    </div>
  );
};

export default TrendingNow;
