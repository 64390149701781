import React, { useContext, useState } from "react";
import PinField from "react-pin-field";
import { useLocation, useNavigate } from "react-router-dom";
import UserAvatar from "../Components/UserAvatar";
import { Context } from "../Context/Context";
import { LoginUserService } from "../Services";
import i18n from "../Translations";

const PinVerificationPage = () => {
  const { state } = useLocation();
  const { setMainProfileAndToken } = useContext(Context);
  const { profile: tempProfile, email, password } = state;
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [codeCompleted, setCodeCompleted] = useState(false);
  const [validInput, setValidInput] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleComplete = async (code: string) => {
    setCodeCompleted(true);
    setValidInput(true);
    try {
      const { profile, token } = await LoginUserService({
        email: email || "",
        password: password || "",
        profile_id: tempProfile.id,
        pin: code,
      });
      setMainProfileAndToken(profile, token);
      navigate("/home");
    } catch (e: any) {
      let message = i18n.t("errors.somethingWrong");
      if (e.response.status === 401) {
        setValidInput(false);
        message = i18n.t("errors.incorrectPin");
      }
      setErrorMessage(message);
      setCodeCompleted(false);
    }
  };

  return (
    <div className="onboarding-page profiles-page">
      <UserAvatar
        image={tempProfile.avatar_url}
        iconSize={160}
        className="pin-field-avatar"
      />
      <h1 className="pin-header">{i18n.t("enterPin.header")}</h1>
      <p className="grey-text pin-subheader">{i18n.t("enterPin.subheader")}</p>
      <div className="input-container">
        <PinField
          length={4}
          validate="0123456789"
          className={`pin-input`}
          autoFocus
          data-valid={validInput}
          onChange={(code) => setCode(code)}
          disabled={codeCompleted}
          onComplete={(code) => handleComplete(code)}
        />
      </div>
      {!validInput && (
        <p className="invalid-text incorrect-pin">{errorMessage}</p>
      )}
      <p className="gold-text">{i18n.t("enterPin.forgotPin")}</p>
    </div>
  );
};

export default PinVerificationPage;
