import { createContext } from "react";
import { FormParams } from "../Containers/types";
import { ProfileModel } from "../Models";

interface ContextProps {
  profiles: any;
  mainProfile: ProfileModel;
  token: string;
  setProfiles: React.Dispatch<React.SetStateAction<string | null>>;
  setMainProfile: React.Dispatch<React.SetStateAction<string | null>>;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setMainProfileAndToken: Function;
}

export const Context = createContext<ContextProps>({
  profiles: [],
  mainProfile: {
    avatar_url: "",
    country: undefined,
    created_at: "0",
    date_of_birth: undefined,
    follows: [],
    gender: undefined,
    id: 0,
    library_content: { artist_ids: [], collection_ids: [], contents: [] },
    name: "",
    permission: {
      id: 0,
      is_public: false,
      music: "none",
      podcasts: "none",
      public_name: "",
      rules: [],
      user_id: 0,
      video: "none",
    },
    permission_id: 0,
    subscriptions: [],
    updated_at: "0",
    user_id: 0,
    zip_code: undefined,
  },
  token: "",
  setProfiles: () => {},
  setMainProfile: () => {},
  setToken: () => {},
  setMainProfileAndToken: () => {},
});
