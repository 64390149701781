import { tableSchema } from '@nozbe/watermelondb'

export default tableSchema({
  name: 'artists',
  columns: [
    { name: 'algolia_id', type: 'number', isIndexed: true },
    { name: 'display_name', type: 'string' },
    { name: 'bio', type: 'string' },
    { name: 'photo', type: 'string' },
    { name: 'popularity_score', type: 'number' },
    { name: 'collections', type: 'string' },
    { name: 'tags', type: 'string' },
    { name: 'flags', type: 'string' },
    { name: 'categories', type: 'string' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ],
})
