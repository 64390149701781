import * as Yup from "yup";
import { mixed, object, string } from "yup";

import i18n from "../Translations";

const REQUIRED = i18n.t("validation.required");

const YupPassword = Yup.string()
  .min(8, i18n.t("validation.passwordLength"))
  .required(i18n.t("validation.required"))
  .matches(/[A-Z]/, i18n.t("validation.oneUpperCase"))
  .matches(/([a-z])/, i18n.t("validation.oneLowerCase"))
  .matches(/(\d)/, i18n.t("validation.oneNumber"));

export interface IProfileSchema {
  country?: string;
  date_of_birth?: Date;
  gender?: "m" | "f";
  name?: string;
  zip_code?: string;
}

export const fullProfileValidation = object().shape({
  country: string().trim().required(REQUIRED),
  date_of_birth: Yup.date().required(REQUIRED),
  gender: mixed().nullable().oneOf(["m", "f"]).required(REQUIRED),
  name: string()
    .trim()
    .required(REQUIRED)
    .min(2, i18n.t("validation.nameLength"))
    .max(20, i18n.t("validation.nameLength")),
  zip_code: Yup.string()
    .trim()
    .required(REQUIRED)
    .min(5, i18n.t("validation.zipCodeLength")),
});

export const fullNameValidation = Yup.object().shape({
  name: Yup.string().trim().required(REQUIRED),
});

export const pinCodeValidation = Yup.object().shape({
  pin: Yup.string().trim().required().length(4),
});

export const passwordValidation = Yup.object().shape({
  password: YupPassword,
});

export const emailValidation = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("validation.email"))
    .required(i18n.t("validation.required")),
});

export const cancelAccountValidation = Yup.object().shape({
  password: YupPassword,
  confirm_password: YupPassword.test(
    "password-match",
    i18n.t("validation.doesNotMatchPassword"),
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const signInValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(i18n.t("validation.email"))
    .required(i18n.t("validation.required")),
  password: Yup.string()
    .min(8, i18n.t("validation.passwordLength"))
    .required(i18n.t("validation.required")),
});
