import _ from "lodash";

import { ALGOLIA_ARTISTS_INDEX_NAME } from "../../Constants";
import { ArtistItemModel } from "../../Models";
import { algoliaGetRelated } from "../Algolia";

export const GetRelatedArtists = (
  artistId: number,
  profile?: any,
  maxRecommendations?: number
) => {
  return algoliaGetRelated({
    id: artistId,
    selectedIndex: ALGOLIA_ARTISTS_INDEX_NAME,
    queryParameters: {
      attributesToRetrieve: ["id", "display_name", "photo"],
    },
    maxRecommendations,
    profile: profile,
  }).then(({ results }) => {
    // @ts-ignore
    const artists: ArtistItemModel[] = results[0]
      ? results[0].hits?.map((a) => _.pick(a, ["id", "display_name", "photo"]))
      : [];
    return { artists, nbPages: results[0].nbPages };
  });
};
