import { ALGOLIA_ARTISTS_INDEX_NAME } from "../../Constants";
import { ArtistModel } from "../../Models";
import {
  databaseCreateMultipleData,
  databaseGetMultipleData,
  hasFreshData,
} from "../../Services/Database";
import { ContentTypes } from "../..//Types";

import { algoliaSearch } from ".";

export const GetArtists = async (
  type: ContentTypes,
  ignoreCache: boolean,
  profile: any
) => {
  if (!ignoreCache && (await hasFreshData(ALGOLIA_ARTISTS_INDEX_NAME))) {
    return databaseGetMultipleData<ArtistModel[]>(
      ALGOLIA_ARTISTS_INDEX_NAME,
      profile,
      15
    );
  }

  return algoliaSearch<ArtistModel>({
    selectedIndex: ALGOLIA_ARTISTS_INDEX_NAME,
    additionalOptions: {
      length: 15,
      offset: 0,
      facetFilters: ["flags.musician:true"],
    },
    nullCache: ignoreCache,
    profile: profile,
  }).then(({ hits }) => {
    databaseCreateMultipleData(ALGOLIA_ARTISTS_INDEX_NAME, hits);
    return hits ?? [];
  });
};
