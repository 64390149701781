export const numberArray: number[] = Array.from(Array(20), (_, i) => i)

export const LOGO_SIZE = 24

export interface TDefaultOrganizedSongs<TypeOfSong> {
  today: Array<TypeOfSong>
  yesterday: Array<TypeOfSong>
  thisWeek: Array<TypeOfSong>
  thisMonth: Array<TypeOfSong>
  earlier: Array<TypeOfSong>
}

export const defaultOrganizedSongs = <
  TypeOfSong,
>(): TDefaultOrganizedSongs<TypeOfSong> => ({
  today: [],
  yesterday: [],
  thisWeek: [],
  thisMonth: [],
  earlier: [],
})
