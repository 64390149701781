//@ts-nocheck
import { store } from "../../Store";
import { isBefore, subHours } from "date-fns";
import { AlgoliaIndices } from "../../Types";
import { HOURS_TO_EXPIRE } from "./constants";
import { AlgoliaModels, DatabaseModel, DatabaseModels } from "./types";
import database from "../../Database";

/** serialize algolia object into database object before storing. */
export function serializeDataBeforeStoring(
  indexName: AlgoliaIndices,
  item: AlgoliaModels[typeof indexName]
) {
  if (indexName === "contents") {
    const data = {
      ...item,
      algoliaId: item.id,
      id: undefined,
      content_collection: item.collection,
    };

    delete data.collection; // can't overwrite this field
    delete data.id;

    return data;
  }

  if (indexName === "artists") {
    const data = {
      ...item,
      algoliaId: item.id,
      id: undefined,
      collections: item.collections,
    };

    delete data.id;

    return data;
  }

  const data = {
    ...item,
    algoliaId: item.id,
    id: undefined,
  };

  delete data.id;

  return data;
}

function getFields(indexName: AlgoliaIndices) {
  if (indexName === "contents") {
    const fields = [
      "title",
      "description",
      "photo",
      "other_artists",
      "artist",
      "content_collection",
      "popularity_score",
      "length_in_seconds",
      "collection_number",
      "languages",
      "tags",
      "flags",
      "categories",
      "lyrics",
      "content_type",
    ];

    return fields;
  }

  if (indexName === "artists") {
    const fields = [
      "display_name",
      "photo",
      "collections",
      "bio",
      "popularity_score",
      "tags",
      "categories",
      "flags",
    ];

    return fields;
  }

  if (indexName === "content_collections") {
    const fields = [
      "name",
      "photo",
      "artist",
      "contents",
      "other_artists",
      "release_date",
      "content_type",
      "flags",
    ];

    return fields;
  }

  return [];
}

/** serialize algolia object into database object after retrieving. */
export function serializeDataAfterRetrieving(
  indexName: AlgoliaIndices,
  item: DatabaseModels[typeof indexName]
) {
  let data = {} as AlgoliaModels[typeof indexName];
  let fields = getFields(indexName);

  fields.forEach((key) => {
    data[key] = item[key];
  });

  if (indexName === "contents") {
    data.collection = item.content_collection;
  }

  data.id = item.algoliaId;

  return data;
}

/** delete data that is duplicated or expired (1 hour). */
export async function sanitizeData(indexName: AlgoliaIndices) {
  const collection = database.get<DatabaseModels[typeof indexName]>(indexName);

  async function removeDuplicated() {
    const items = await collection.query().fetch();

    let itemIDs = items.map((item) => item.algoliaId);
    const uniqueItemIDs = [...new Set(itemIDs)];

    // remove the unique IDs, so now we can delete all the rest (duplicates)
    uniqueItemIDs.forEach((id) => itemIDs.splice(itemIDs.indexOf(id), 1));

    const prepareDestroyItems: DatabaseModel[] = [];
    const idsToBeDeleted: string[] = [];
    itemIDs.forEach((id) => {
      const item = items.find(
        (i) => i.algoliaId === id && !idsToBeDeleted.includes(i.id)
      );

      if (!item) {
        return;
      }

      idsToBeDeleted.push(item?.id);
      prepareDestroyItems.push(item.prepareDestroyPermanently());
    });

    if (!prepareDestroyItems.length) {
      return;
    }

    await database.batch(...prepareDestroyItems);
  }

  async function removeExpired() {
    const items = await collection.query().fetch();

    const prepareDestroyItems: DatabaseModel[] = [];
    items.forEach((item) => {
      const hasExpired = isBefore(
        item.createdAt,
        subHours(new Date(), HOURS_TO_EXPIRE)
      );

      if (hasExpired) {
        prepareDestroyItems.push(item.prepareDestroyPermanently());
      }
    });

    await database.batch(...prepareDestroyItems);
  }

  await database.write(async () => {
    await removeDuplicated();
    await removeExpired();
  });
}

export const filterDataByFlags = (
  data: AlgoliaModels[AlgoliaIndices][],
  profile: any
) => {
  const {
    blockedKolIsha: { isBlocked: isBlockedKolIsha },
    adultOnly: { isBlocked: isBlockedAdultOnly },
  } = profile?.permissionFilterSettings;

  const filterFlags = {
    flagsKolIsha: isBlockedKolIsha,
    flagsAdultOnly: isBlockedAdultOnly,
  };

  if (!filterFlags.flagsKolIsha && !filterFlags.flagsAdultOnly) {
    return data;
  }

  const result = data.filter((item) => {
    const isAdultOnlyValidate =
      filterFlags.flagsAdultOnly && item?.flags?.adult_only
        ? item?.flags?.adult_only !== filterFlags.flagsAdultOnly
        : true;
    const isKolIshaValidate =
      filterFlags.flagsKolIsha && item?.flags?.kol_isha
        ? item?.flags?.kol_isha !== filterFlags.flagsKolIsha
        : true;
    const isAdultOnlyCollectionValidate =
      filterFlags.flagsAdultOnly && item?.content_collection?.flags?.adult_only
        ? item?.content_collection?.flags?.adult_only !==
          filterFlags.flagsAdultOnly
        : true;

    const isKolIshaCollectionValidate =
      filterFlags.flagsKolIsha && item?.content_collection?.flags?.kol_isha
        ? item?.content_collection?.flags?.kol_isha !== filterFlags.flagsKolIsha
        : true;

    return (
      isAdultOnlyValidate &&
      isKolIshaValidate &&
      isAdultOnlyCollectionValidate &&
      isKolIshaCollectionValidate
    );
  });

  return result;
};

export const filterByBlockedContent = (
  data: AlgoliaModels[AlgoliaIndices][],
  profile: any
) => {
  const blockedCategories =
    profile.permissionFilterSettings?.blockedCategories.blockedIds;
  const blockedArtists =
    profile.permissionFilterSettings?.blockedArtists.blockedIds;

  const result = data.filter((item) => {
    const isBlockedCategory = item.categories?.some((cat) =>
      blockedCategories?.includes(cat.category_id)
    );

    let isBlockedArtist = false;

    if (item.artist?.id) {
      // contents
      isBlockedArtist = blockedArtists?.includes(item.artist.id);
    }

    if (item.display_name && item.id) {
      // artists
      isBlockedArtist = blockedArtists?.includes(item.id);
    }

    return !(isBlockedCategory || isBlockedArtist);
  });

  return result;
};
