import React from "react";
import "../../Cards.css";
import "../../App.css";
import AlbumCard from "../../Components/Cards/AlbumCard";
import ScrollableList from "../../Components/ScrollableList";
import { SectionProps } from "../types";
import i18n from "../../Translations";

const RecentlyListened: React.FC<SectionProps> = ({
  collection,
  imageClass = "",
}) => {
  return (
    <div></div>
    // <div>
    //   {collection.length ? (
    //     <ScrollableList
    //       title={i18n.t("titles.newReleases")}
    //       itemClassName={`card-container ${collection.length > 0 && "hover"}`}
    //     >
    //       {collection.map((item: any) => {
    //         return (
    //           <div key={item.id}>
    //             <AlbumCard item={item} />
    //           </div>
    //         );
    //       })}
    //     </ScrollableList>
    //   ) : null}
    // </div>
  );
};

export default RecentlyListened;
