import { SearchOptions } from "@algolia/client-search";

import {
  ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME,
  ALGOLIA_DESCENDING_SUFFIX,
} from "../../Constants";
import { AlbumModel, ArtistItemModel } from "../../Models";
import { algoliaSearch } from "../Algolia";
import { ContentTypes, PhotoSizeTypes } from "../../Types";

const attributes = [
  "id",
  "name",
  "photo",
  "release_date",
  "artist",
  "contents",
];

export interface IContent {
  id: number;
  name: string;
  photo: PhotoSizeTypes;
  release_date?: string;
  artist: ArtistItemModel;
  contents: any;
  profile: any;
}

export const GetAlbumData = (
  artistId: number,
  contentType: ContentTypes,
  options: SearchOptions = {},
  profile: any
) => {
  return algoliaSearch({
    selectedIndex: `${ALGOLIA_CONTENT_COLLECTIONS_INDEX_NAME}${ALGOLIA_DESCENDING_SUFFIX}`,
    additionalOptions: {
      attributesToRetrieve: attributes,
      facetFilters: [`artist.id:${artistId}`, `content_type:${contentType}`],
      ...options,
    },
    profile: profile,
  }).then((res) => {
    let result: AlbumModel[] = [];
    if (res.hits) {
      //@ts-ignore
      result = res.hits.map((c: any) => {
        return {
          id: c.id,
          name: c.name,
          photo: c.photo,
          release_date: c.release_date,
          artist: c.artist,
          contents: c.contents,
        };
      });
    }

    return { albums: result, nbPages: res.nbPages };
  });
};
